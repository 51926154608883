import React, { useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { getAllMessages, getTop10Topic, getTotalOfSentiments } from "../Helpers/GetData";
import { DropdownList } from "react-widgets";
import "react-widgets/styles.css";
import { Loader } from '@aws-amplify/ui-react';
import secureLocalStorage from "react-secure-storage";
import { getLoaderComponent } from "../../../Utils/Common";

function PieChart({ command }) {
    const [Data, setData] = useState({
        labels: [],
        datasets: [
            {
                data: [],
            },
        ]

    });
    const [yValue, setYValue] = useState("Count");
    const [xValue, setXValue] = useState("Topic");
    const [title, setTitle] = useState("Topic Counts");
    const [isLoaded, setIsLoaded] = useState(false);
    const loggedInUser = JSON.parse(secureLocalStorage.getItem("user"));
    ChartJS.defaults.font.family = "AvenirLTProRoman"
    ChartJS.register(ArcElement, Tooltip, Legend, Colors,);


    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'right'
            },
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
        }
    }

    if (command === "queryAllTopicCounts" && !isLoaded) {
        getAllMessages(loggedInUser).then((messages) => {
            getTop10Topic(messages).then((data) => {
                setData(data)
                setTitle("Top 10 Topics")
                setIsLoaded(true)
            }).catch(err => console.log(err));
        }).catch(err => console.log(err));
    }
    else if (command === "getSentimentCounts" && !isLoaded) {
        getAllMessages(loggedInUser).then((messages) => {
            getTotalOfSentiments(messages).then((data) => {
                setData(data)
                setTitle("Sentiment Counts")
                setIsLoaded(true)
            }).catch(err => console.log(err));;
        }).catch(err => console.log(err));
    }


    if (isLoaded) {
        return (
            <>
                <div className="insights-card-top-container">
                    <div className="insights-card-title">
                        <h2>{title}</h2>
                    </div>
                </div>
                <div className="pie-chart-container">
                    <Pie options={options} data={Data} />
                </div>
            </>
        )
    }
    else {
        return (
            <>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", height: "100%" }}>
                    {getLoaderComponent()}
                </div>
            </>
        )
    }
}

export default PieChart;