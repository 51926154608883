import { useState, useEffect } from "react";
import { Alert, Theme, ThemeProvider } from '@aws-amplify/ui-react';
import { useNavigate } from "react-router-dom";
import { API, Auth } from 'aws-amplify';
import { getCurrentUserID } from "../../Pages/VisualMapPage/Helpers/GetData.js";
import { listCustomerSubscriptions } from "../../graphql/queries";


export default function SubscriptionAlertBanner() {

    // Amplify Refyne Customer ID - for authenticating queries
    const [refyneCustomerId, setRefyneCustomerId] = useState(null);

    // Refyne Subscription Status - for choosing whether to display banner or not
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);

    useEffect(() => {

        async function getRefyneCustomerId() {
            const result = await getCurrentUserID();
            setRefyneCustomerId(result);
        }

        async function checkRefyneAccountStatus() {

            let listCustomerSubscriptionsQuery = {
                query: listCustomerSubscriptions,
                variables: {
                    customer_id: refyneCustomerId
                }
            }
            var apiData = await API.graphql(listCustomerSubscriptionsQuery);
            const apiResponse = apiData.data.listCustomerSubscriptions.items[0].status;
            setSubscriptionStatus(apiResponse);
        }

        getRefyneCustomerId().then(() => {
            checkRefyneAccountStatus()
        });
    }, []);

    const subscriptionExpiredText = "Your subscription is inactive and you are unable to receive new messages. Navigate to Settings to select a plan and start receiving messages."

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `settings`;
        navigate(path);
    }

    if (subscriptionStatus === "inactive" || subscriptionStatus === false) {
        return (
            <Alert onClick={routeChange} variation="error">{subscriptionExpiredText}</Alert>

        );
    }

}