export function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

import { ThreeCircles } from 'react-loader-spinner'
export function getLoaderComponent(height, width) {
    if (height === undefined) {
        height = 50;
    }
    if (width === undefined) {
        width = 50;
    }

    return (
        <ThreeCircles
            visible={true}
            height={height}
            width={width}
            color="#666666"
            ariaLabel="three-circles-loading"
            wrapperStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
            }}
            wrapperClass=""
            outerCircleColor="rgba(255, 0, 0, 0.0)"
        />
    );
}