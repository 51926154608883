import React from "react";
import "./Message.css";
import { ReactComponent as LabelArt } from "../../../Images/label_grey_24dp.svg"
import moreArt from "../../../Images/more_horiz_black_24dp.svg"
import deleteArt from "../../../Images/delete_outline_black_24dp.svg"
import { getISO8601TimeAndDate } from "../Helpers/MessageListHelpers.js";
import { softDeleteMessage } from "../Helpers/getData.js";
import AddLabel from "./AddLabel.js";

class Message extends React.Component {
    constructor(props) {
        super(props);
        this.handleLabelPopupClick = this.handleLabelPopupClick.bind(this);
        this.state = { label: this.props.label, showAddLabel: false };
    }

    componentDidMount() {
        this.setState({ showAddLabel: false });
    }

    componentWillUnmount() {
        this.setState({ showAddLabel: false });
    }

    deleteMessage() {
        softDeleteMessage(this.props.messageID).then(() => {
            this.props.updateOnDelete(this.props.messageID);
        }).catch(err => console.log(err));
    }
    /*
    *   This function handles the label popup
    */
    handleLabelPopupClick() {
        this.setState({ showAddLabel: !this.state.showAddLabel });
    }

    formatTopic(topic) {
        let topicString = "";
        if (topic === null) {
            return "No topic";
        }
        else {
            for (let i = 0; i < topic.length; i++) {
                if (i === topic.length - 1) {
                    topicString += topic[i];
                }
                else {
                    topicString += topic[i] + ", ";
                }
            }
            return topicString;
        }
    }

    formatRatingColor(rating) {
        if (rating === null) {
            return "#666666";
        }
        else if (rating >= 0 && rating <= 1) {
            return "#ff4545";
        }
        else if (rating > 1 && rating <= 2) {
            return "#ffa534";
        }
        else if (rating > 2 && rating <= 3) {
            return "#ffe234";
        }
        else if (rating > 3 && rating <= 4) {
            return "#b7dd29";
        }
        else if (rating > 4 && rating <= 5) {
            return "#57e32c";
        }
        else {
            return "#666666";
        }
    }

    render() {
        return (
            <>
                <div className="top-container">
                    <div className="message-top-flex-container">
                        <div className="message-date-container">
                            {this.props.timestamp === undefined ? <p className="message-date">No date</p> : <p className="message-date">{getISO8601TimeAndDate(this.props.timestamp)}</p>}

                        </div>
                        <div className="toolbar-container">
                            <LabelArt className="toolbar-button" disabled={this.state.addLabelPopIconDisabled} onClick={this.handleLabelPopupClick} fill={this.props.label !== null && this.props.label.color !== null ? this.props.label.color : "#666666"} src={LabelArt} alt="Mark label" id="label-button-message-page" />
                            <AddLabel handleLabelPopupClick={this.handleLabelPopupClick} showAddLabel={this.state.showAddLabel} messageID={this.props.messageID} labels={this.props.allLabels} updateOnAddLabel={this.props.updateOnAddLabel} updateOnRemoveLabel={this.props.updateOnRemoveLabel} label={this.props.label} isSelected={this.props.label} />
                            <img className="toolbar-button" src={moreArt} alt="Mark label" />
                            <img className="toolbar-button" src={deleteArt} alt="Mark label" onClick={e => this.deleteMessage(this.props.messageID)} />

                        </div>

                    </div>
                    <hr className="hr-line-message"></hr>
                </div>
                <div className="message-flex-container">

                    <div className="message-content-flex-container">
                        <div className="message-content-container">
                            <div className="message-title-container">
                                <h1 className="message-title">{this.props.messageTitle}</h1>
                            </div>
                            <div className="message-content">
                                <p className="message-content">{this.props.messageBody}</p>

                            </div>
                            <div className="message-attributes-wrapper">
                                <div className="message-attributes-flex-container">
                                    {this.props.topic !== null ? <div className="attribute-container"><p className="message-attribute">{this.formatTopic(this.props.topic)}</p> <label className="attribute-label"><i>Topic</i></label></div> : <div className="attribute-container"><p className="message-attribute">None</p> <label className="attribute-label"><i>Topic</i></label></div>}
                                    {this.props.rating !== null ? <div className="attribute-container"><p className="message-attribute" style={{ backgroundColor: this.formatRatingColor(this.props.rating), width: "1.2rem" }}>{this.props.rating}</p> <label className="attribute-label"><i>Rating</i></label></div> : <div className="attribute-container"><p className="message-attribute">None</p> <label className="attribute-label"><i>Rating</i></label></div>}
                                    {this.props.email !== "" ? <div className="attribute-container"><p className="message-attribute">{this.props.email}</p> <label className="attribute-label"><i>Email</i></label></div> : <div className="attribute-container"><p className="message-attribute">None</p> <label className="attribute-label"><i>Email</i></label></div>}
                                    {this.props.city !== null ? <div className="attribute-container"><p className="message-attribute">{this.props.city}</p> <label className="attribute-label"><i>City</i></label></div> : <div className="attribute-container"><p className="message-attribute">None</p> <label className="attribute-label"><i>City</i></label></div>}
                                    {this.props.state !== null ? <div className="attribute-container"><p className="message-attribute">{this.props.state}</p> <label className="attribute-label"><i>State</i></label></div> : <div className="attribute-container"><p className="message-attribute">None</p> <label className="attribute-label"><i>State</i></label></div>}
                                    {this.props.country !== null ? <div className="attribute-container"><p className="message-attribute">{this.props.country}</p> <label className="attribute-label"><i>Country</i></label></div> : <div className="attribute-container"><p className="message-attribute">None</p> <label className="attribute-label"><i>Country</i></label></div>}
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </>
        )
    }
}

export default Message;