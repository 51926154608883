import React, { useState } from "react";
import "./Tooltip.css";

const Tooltip = ({ tooltipText, mouseOverText, children }) => {
    const [showTooltip, setShowTooltip, link] = useState(false);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <div className="tooltip-container">
            <span
                className="tooltip-icon"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >{tooltipText}</span>
            {showTooltip && <div className="tooltip-content">{mouseOverText}</div>}
            {children}
        </div>
    );
};

export default Tooltip;
