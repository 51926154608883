import { API } from "aws-amplify";
import { listCustomers } from "../graphql/queries";


//
// Returns a list of customer IDs for routes to contact forms
//
export async function getCustomerIds() {

    // Array object to hold customer IDs for routes
    const customer_ids = []

    // Define the base query object

    let baseQueryObject = { 
        query: listCustomers
    }

    // Make the GraphQL API request
    var apiData = await API.graphql(baseQueryObject);
    let customerObjects = apiData.data.listCustomers.items;
    
    // Iterate through each customer, adding the customer_id attribute to the array.
    // Return the array.
    customerObjects.forEach(element => customer_ids.push(element.customer_id))

    return customer_ids;
}

//
// Given a customer id, return the associated secret
//
export async function getCustomerSecret(refyneCustomerId) {

    // Define the base query object


    let baseQueryObject = { 
        query: listCustomers,
        variables: { 
            customer_id: refyneCustomerId
        }
    }   

    // Make the GraphQL API request
    var apiData = await API.graphql(baseQueryObject);
    let customerSecret = apiData.data.listCustomers.items[0].secret
    
    // Iterate through each customer, adding the customer_id attribute to the array.
    // Return the array.
    return customerSecret;
}