import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom'
import { Turn as Hamburger } from 'hamburger-react'
import './Navbar.css';
import { Button } from './Button';
import RefyneBanner from '../../Images/Refyne-Banner.jpeg';


function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true)

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        }
        else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton()
    }, []);

    window.addEventListener('resize', showButton);
    return (
        <>
            <nav className="navbar" >
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        <img src={RefyneBanner} alt="Refyne logo" className="logo-image" />
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        <Hamburger toggled={click} toggle={setClick} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className="nav-item">
                            <Link to='/' className="nav-links" onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/LearnMore' className="nav-links" onClick={closeMobileMenu}>
                                Learn more
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/Contact' className="nav-links" onClick={closeMobileMenu}>
                                Contact
                            </Link>
                        </li>
                        <li className="nav-item">

                        </li>
                    </ul>
                    <Link to='/SignIn' onClick={closeMobileMenu}>
                        <Button buttonStyle='btn--outline'>Sign In</Button>
                    </Link>
                </div>
            </nav>
        </>
    )
}
export default Navbar



