import React, { useState } from "react";
import "./CSVImport.css";
import Sidebar from "../../Components/SidebarNav/Sidebar";
import { useCSVReader } from 'react-papaparse';
import API_URLS from "../../config";
import CSVImage from "../../Images/csv.png";
import secureLocalStorage from "react-secure-storage";
import axios from 'axios'
import DataTable from 'react-data-table-component';


function CSVImport() {
    const { CSVReader } = useCSVReader();
    const [csvData, setCsvData] = useState([]);
    const [didUpload, setDidUpload] = useState(false);
    const [didSubmit, setDidSubmit] = useState(false);
    // DataTable
    const ExpandedComponent = ({ data }) => <div style={{ "paddingLeft": "2%", "textAlign": "left" }} > {data.message}</div >
    const columns = [
        { name: 'First Name', selector: row => row.firstName, sortable: true },
        { name: 'Last Name', selector: row => row.lastName, sortable: true },
        { name: 'Email', selector: row => row.email, sortable: true },
        { name: 'Address', selector: row => row.address, sortable: true },
        { name: 'City', selector: row => row.city, sortable: true },
        { name: 'State', selector: row => row.state, sortable: true }
    ]

    /*
    * Sends a request to the API Gateway to send the data to the SQS queue
        * @param {Object} data - The data to be sent to the SQS queue
        * @param {string} data.message - The message to be sent to the SQS queue
        * @param {string} data.firstName - The first name of the user
        * 
        * @returns {void}
        */
    function sendRequest(requestData) {
        const loggedInUser = JSON.parse(secureLocalStorage.getItem("user"));


        let data = JSON.stringify({
            "message": String(requestData.message),
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: API_URLS.processMessageLambda,
            headers: {
                'customerid': String(loggedInUser.username),
                'customersecret': String(loggedInUser.customersecret),
                'Content-Type': 'application/json'
            },
            data: requestData
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            });

    }

    /*
    * Submits the data to the API Gateway
    * @returns {void}
    * */
    async function submitData() {
        if (didUpload === false && didSubmit === true) {
            return;
        }
        else {
            try {
                console.log(csvData)
                sendRequest(csvData)
                /*
                for (var i = 0; i < csvData.length; i++) {
                    sendRequest(csvData[i])
                    //wait 100 miliseconds
                    await new Promise(r => setTimeout(r, 60));
                }
*/
                setDidUpload(false);
                setDidSubmit(true);
            } catch (e) {
                console.log("Error" + e);
            }
        }
    }

    return (
        <>
            <div className="insights-page-safe-area">
                <Sidebar></Sidebar>
                <div className="insights-page-title">
                    <h1>CSV Import</h1>
                </div>

                <CSVReader
                    onUploadAccepted={(results) => {
                        console.log(results.data)
                        setCsvData(results.data);
                        setDidUpload(true);
                    }}
                    config={{
                        header: true,
                        skipEmptyLines: true,
                    }}
                >
                    {({
                        getRootProps,
                        acceptedFile,
                        ProgressBar,
                        getRemoveFileProps,
                    }) => (
                        <>
                            <div className="CSV-top-bar">
                                <div className="import-csv-image-container">
                                    <img className="import-csv-image" src={CSVImage} alt={"CSVUpload"} />
                                </div>
                                <div className="import-csv-text">
                                    {acceptedFile && acceptedFile.name}
                                </div>
                                <div className="upload-btn-wrapper">
                                    <div>
                                        <button type='button' {...getRootProps()} className="CSV-File-Selector" >
                                            Upload
                                        </button>
                                    </div>
                                </div>
                                <div className="progress-bar">
                                    <ProgressBar style={{ backgroundColor: "#8ADB53" }} />
                                </div>
                            </div>
                            <div className="import-csv-bottom-container">
                                <DataTable title="Results" columns={columns} data={csvData} expandableRowsComponent={ExpandedComponent} dense pagination striped highlightOnHover responsive expandableRows expandOnRowClicked />
                            </div>
                            <button {...getRemoveFileProps()} onClick={e => { setCsvData([]); setDidUpload(false); setDidSubmit(false) }} className="CSV-File-Selector-Remove">
                                Remove
                            </button>
                            <button onClick={e => submitData()} className="CSV-File-Selector" disabled={!didUpload}>
                                {didSubmit ? "Submitted" : "Submit"}
                            </button>
                        </>
                    )}
                </CSVReader>
            </div>
        </>
    );
}

export default CSVImport;
