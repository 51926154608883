import { MapView, Heading, Text } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { useState } from 'react';
import { Marker, Popup } from 'react-map-gl';
import './.PopupOverride.css'; // Custom CSS file for overriding styles
import { getDateDiff } from "./Helpers/GetData.js";


export function MarkerWithPopup({ latitude, longitude, props }) {
  const [showPopup, setShowPopup] = useState(false);

  const handleMarkerClick = ({ originalEvent }) => {
    originalEvent.stopPropagation();
    setShowPopup(true);
  };

  const CustomPopup = ({ longitude, latitude, onClose }) => {
    const popupStyle = {
      position: 'absolute',
      padding: '10px',
      background: 'transparent',
      border: 'none',
      pointerEvents: 'none',
    };
    return (
      <Popup
        longitude={longitude}
        latitude={latitude}
        onClose={onClose}
        anchor="top"
        className="custom-popup"
        style={popupStyle}
      >
        <div className="popup-content" style={popupStyle}>


          <MessageLocationCardSmallNew
            messageText={props.message_string}
            mailAddress={"mailto:" + props.email}
            senderName={props.first_name + " " + props.last_name}
            messageTimestamp={getDateDiff(props.timestamp)}
            key={props.message_id}
          />
        </div>

      </Popup>
    );
  };

  return (
    <>
      <Marker
        latitude={latitude}
        longitude={longitude}
        onClick={handleMarkerClick}
      />
      {showPopup && (
        <CustomPopup
          latitude={latitude}
          longitude={longitude}
          offset={{ bottom: [0, -40] }}
          onClose={() => setShowPopup(false)}
        >

        </CustomPopup>
      )}
    </>
  );
}

