// Description: Validation functions for user input

/**
 * @param {string} email
 * @returns {boolean}
 * @description Validate email
 * Regex from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
 */
export function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

/**
 * @param {string} password
 * @returns {boolean}
 * @description Validate password
 * Minimum eight characters, at least one letter and one number, special characters allowed
 */
export function validatePassword(password) {

    var re = /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/;
    return re.test(password);
}

/**
 * @param {string} name
 * @returns {boolean}
 * @description Validate name
 * Minimum two characters, only letters and spaces
 */
export function validateName(name) {
    var re = /^[a-zA-Z ]{2,30}$/;
    return re.test(name);
}

/**
 * @param {string} phone
 * @returns {boolean}
 * @description Validate phone
 * Only numbers, exactly 10 characters
 */
export function validatePhone(phone) {
    var re = /^[0-9]{10}$/;
    return re.test(phone);
}

/**
 * @param {string} address
 * @returns {boolean}
 * @description Validate address
 * Minimum two characters, only letters, numbers, and spaces
 */
export function validateAddress(address) {
    var re = /^[a-zA-Z0-9 ]{2,30}$/;
    return re.test(address);
}

/**
 * @param {string} city
 * @returns {boolean}
 * @description Validate city
 * Minimum two characters, only letters and spaces
 */
export function validateCity(city) {
    var re = /^[a-zA-Z ]{2,30}$/;
    return re.test(city);
}

/**
 * @param {string} state
 * @returns {boolean}
 * @description Validate state
 * Minimum two characters, only letters and spaces
 * TODO: Add list of states
 */
export function validateState(state) {
    var re = /^[a-zA-Z ]{2,30}$/;
    return re.test(state);
}

/**
 * @param {string} zip
 * @returns {boolean}
 * @description Validate zip
 * Only numbers, exactly 5 characters
 * TODO: Add list of zip codes
 */
export function validateZip(zip) {
    var re = /^[0-9]{5}$/;
    return re.test(zip);
}

/**
 * @param {string} cardNumber
 * @returns {boolean}
 * @description Validate card number
 * Only numbers, exactly 16 characters
 */
export function validateCardNumber(cardNumber) {
    var re = /^[0-9]{16}$/;
    return re.test(cardNumber);
}

/**
 * @param {string} cardName
 * @returns {boolean}
 * @description Validate card name
 * Minimum two characters, only letters and spaces
 */
export function validateCardName(cardName) {
    var re = /^[a-zA-Z ]{2,30}$/;
    return re.test(cardName);
}

/**
 * @param {string} cardExp
 * @returns {boolean}
 * @description Validate card expiration
 * Only numbers, exactly 4 characters
 */
export function validateCardExp(cardExp) {
    var re = /^[0-9]{4}$/;
    return re.test(cardExp);
}

/**
 * @param {string} cardCVV
 * @returns {boolean}
 * @description Validate card CVV
 * Only numbers, exactly 3 characters
 */
export function validateCardCVV(cardCVV) {
    var re = /^[0-9]{3}$/;
    return re.test(cardCVV);
}

/**
 * Validates date for birthdays
 * @param {*} day 
 * @param {*} month 
 * @param {*} year 
 * @returns {boolean}
 */
export function validateBirthday(day, month, year) {
    if (day < 1 || day > 31) {
        return false;
    }
    if (month < 1 || month > 12) {
        return false;
    }
    if (year < 1900 || year > new Date().getFullYear()) {
        return false;
    }

    return true;
}


