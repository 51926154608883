import React from "react";
import { useNavigate } from "react-router-dom";
import "./Card.css";

/*
 * Returns the SVG for the card
 * @param {string} cardTitle - The title of the card
 * @returns {JSX.Element} - The SVG for the card
 * @example
 * getSVG("messages");
*/
function getSVG(cardTitle) {
    switch (cardTitle) {
        case "messages":
            return (
                <svg id="inbox_black_48dp" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 48 48">
                    <path id="Path_34" data-name="Path 34" d="M0,0H48V48H0Z" fill="none" />
                    <path id="Path_35" data-name="Path 35" d="M40.333,3H7.643a4.611,4.611,0,0,0-4.62,4.667L3,40.333A4.645,4.645,0,0,0,7.643,45h32.69A4.68,4.68,0,0,0,45,40.333V7.667A4.665,4.665,0,0,0,40.333,3Zm0,28H31a7,7,0,0,1-14,0H7.643V7.667h32.69Z" fill="#747474" />
                </svg>
            );
        case "insights":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="40.332" height="28.729" viewBox="0 0 40.332 28.729">
                    <g id="insights_black_24dp" transform="translate(0 4.729)">
                        <g id="Group_8" data-name="Group 8">
                            <rect id="Rectangle_76" data-name="Rectangle 76" width="24" height="24" fill="none" />
                        </g>
                        <g id="Group_10" data-name="Group 10" transform="translate(0 -12.729)">
                            <g id="Group_9" data-name="Group 9" transform="translate(1 3)">
                                <path id="Path_24" data-name="Path 24" d="M36.756,8c-2.592,0-4.04,2.967-3.45,5.172l-6.347,7.336a3.176,3.176,0,0,0-1.859,0l-4.559-5.255c.608-2.2-.84-5.193-3.45-5.193-2.592,0-4.058,2.967-3.45,5.193L5.487,24.63C3.574,23.95,1,25.619,1,28.607a3.889,3.889,0,0,0,3.576,4.121c2.592,0,4.04-2.967,3.45-5.172l8.135-9.4a3.176,3.176,0,0,0,1.859,0l4.559,5.255c-.608,2.2.84,5.193,3.45,5.193,2.592,0,4.058-2.967,3.45-5.193L35.845,16.1c1.913.68,4.487-.989,4.487-3.977A3.889,3.889,0,0,0,36.756,8Z" transform="translate(-1 -3)" fill="#8adb53" />
                            </g>
                        </g>
                    </g>
                </svg>
            );
        case "visual map":
            return (
                <svg></svg>
            );
        case "import":
            return (
                <svg id="cloud_upload_black_24dp" xmlns="http://www.w3.org/2000/svg" width="40" height="43.785" viewBox="0 0 48 43.785">
                    <path id="Path_30" data-name="Path 30" d="M0,0H48V43.785H0Z" fill="none" />
                    <path id="Path_31" data-name="Path 31" d="M38.7,17.509C37.34,9.793,31.28,4,24,4c-5.78,0-10.8,3.668-13.3,9.036C4.68,13.751,0,19.455,0,26.365c0,7.4,5.38,13.419,12,13.419H38c5.52,0,10-5.01,10-11.183C48,22.7,43.9,17.911,38.7,17.509ZM28,24.129v8.946H20V24.129H14L24,12.946,34,24.129Z" fill="#5599f7" />
                </svg>
            );
        case "settings":
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="feather feather-settings"
                    viewBox="0 0 24 24"
                >
                    <circle cx="12" cy="12" r="3"></circle>
                    <path d="M19.94 13H22v-2h-2.06a8 8 0 00-15.88 0H2v2h2.06a8 8 0 0015.88 0z"></path>
                </svg>
            );
        default:
            return null;
    }
}

/*
 * Returns the JSX for the card
 * @param {useNavigate} navigate - The navigate function
 * @param {string} cardTitle - The title of the card
 * @returns Nothing
 * @example
 * getCard("messages");
 */
function navigateTo(navigate, cardTitle) {
    // Navigate to the correct page
    switch (cardTitle) {
        case "reviews":
            navigate("/Messages");
            break;
        case "insights":
            navigate("/insights");
            break;
        case "visual map":
            navigate("/VisualMap");
            break;
        case "import":
            navigate("/Import");
            break;
        case "settings":
            navigate("/settings");
            break;
        default:
            break;
    }
}

/*
 * The card component
 * @param {string} title - The title of the card
 *        {string} description - The description of the card
 *        {number} messages - The number of messages
 *        {string} importTime - The time of the last import
 * @returns {JSX.Element} - The card component
 * @example
 * <Card title="Messages" description="All your messages" messages={20} />
*/
function Card({ title, description, messages, importDate, importTime }) {
    const navigate = useNavigate();

    return (
        <div className="cardSection" onClick={() => {
            navigateTo(navigate, title.toLowerCase());
        }}>
            <div className="cardHeader">
                <h1 className="cardTitle">{title}</h1>
                {messages != null && <div className="rightNotificationHeader">
                    <h3 className="rightNotificationNumber">{messages}</h3>
                    <div className="notificationRedCircle"></div>
                </div>}
            </div>
            <p className="cardMessageSection">{description}</p>
            <div className="cardFooter">
                <div className="cardIcon">{getSVG(title.toLowerCase())}</div>
                {title === "Import" && <p className="lastImportNotification"><i>Last import was on {importDate} at {importTime}</i></p>}
            </div>
        </div>
    );
}

export default Card;