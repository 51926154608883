/* import logo from './logo.svg'; */
/* eslint-disable */
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Authenticator } from '@aws-amplify/ui-react';
//import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react'
import RequireAuth from "./Utils/RequireAuth";
import Home from "./Pages/HomePage/Home";
import SignUp from "./Pages/SignUp/SignUp";
import Messages from "./Pages/MessagesPage/Messages";
import SignIn from "./Pages/SignIn/SignIn";
import LandingPage from "./Pages/LandingPage/Pages/Home"
import InputMessagePage from "./Pages/InputMessagePage/InputMessagePage"
import VisualMapPage from "./Pages/VisualMapPage/VisualMap"
import EmailImportPage from './Pages/EmailImportPage/EmailImportPage';
import ImportPage from "./Pages/ImportPage/ImportPage"
import InsightsPage from "./Pages/InsightsPage/Insights"
import CSVImport from "./Pages/CSVImportPage/CSVImport"
import SettingsPage from "./Pages/SettingsPage/Settings";
import ContactFormGeneratorPage from "./Pages/ContactFormGeneratorPage/ContactFormGenerator";
import ContactForm from "./Pages/ContactFormGeneratorPage/ContactForm";
import LearnMore from "./Pages/LandingPage/Pages/LearnMore";
import Contact from "./Pages/LandingPage/Pages/Contact";
import '@aws-amplify/ui-react/styles.css';
import { getCustomerIds } from "./Utils/ListCustomers";
import { useState, useEffect } from "react";


function MyRoutes() {

  // Amplify Refyne Customer IDs
  const [refyneCustomerIds, setrefyneCustomerIds] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isLoaded, setisLoaded] = useState(false);

  useEffect(() => {

    async function getRefyneCustomerIds() {
      const result = await getCustomerIds();
      setrefyneCustomerIds(result);
      setLoading(false);
      setisLoaded(true);

    }

    if (isLoaded === false) {
      getRefyneCustomerIds()
    }

  }, []);


  return (
    <div className="App">
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap"
        rel="stylesheet"
      />
      <BrowserRouter>

        <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/LearnMore" element={<LearnMore />}></Route>
          <Route path="/Contact" element={<Contact />}></Route>
          <Route path="/Home" element={<RequireAuth><Home /></RequireAuth>}></Route>
          <Route path="/SignIn" element={<SignIn />}></Route>
          <Route path="/SignUp" element={<SignUp />}></Route>
          <Route path="/Messages" element={<RequireAuth><Messages /></RequireAuth>}></Route>
          <Route path="/Insights" element={<RequireAuth><InsightsPage /></RequireAuth>}></Route>
          <Route path="/LandingPage" element={<LandingPage />}></Route>
          <Route path="/InputMessagePage" element={<InputMessagePage />}></Route>
          <Route path="/VisualMap" element={<RequireAuth><VisualMapPage /></RequireAuth>}></Route>
          <Route path="/EmailImport" element={<RequireAuth><EmailImportPage /></RequireAuth>}></Route>
          <Route path="/Import" element={<RequireAuth><ImportPage /></RequireAuth>}></Route>
          <Route path="/CSVImport" element={<RequireAuth><CSVImport /></RequireAuth>}></Route>
          <Route path="/Settings" element={<RequireAuth><SettingsPage /></RequireAuth>}></Route>
          <Route path="/ContactForm-Import" element={<RequireAuth><ContactFormGeneratorPage /></RequireAuth>}></Route>


          {
            // Render the contact form routes once customer IDs have been retrieved
            !isLoading &&
            <Route path="contact/:userId" element={<ContactForm />} />
          }


        </Routes>
      </BrowserRouter>
    </div>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}
export default App;