import axios from 'axios';

const CREATE_STRIPE_PORTAL_SESSION_API = process.env.REACT_APP_STRIPE_PORTAL_SESSION_ENDPOINT;

export async function getStripePortalSessionUrl(customerId, customerSecret) {
    const body = {
        customerId: customerId
    };
    const headers = {
        'customerid': customerId,
        'customersecret': customerSecret
    };
    const res = await axios.post(CREATE_STRIPE_PORTAL_SESSION_API, body, { headers: headers });

    return res.data.stripePortalSessionUrl;
}