import React, { useState } from "react";
import "./Insights.css";
import BarChart from "./Components/BarChart";
import PieChart from "./Components/PieChart";
import LineChart from "./Components/LineChart";
import { DropdownList } from "react-widgets";
import "react-widgets/styles.css";
import { getTotalTopics, getAvgScore, getTop10Topic, getTrendingTopicPastWeek, getTotalMessages, getTopicAndAvgRating, getTopCity, getTopState, getTopCountry, getTotalLocations, getAllMessages } from "./Helpers/GetData.js";
import { Loader } from '@aws-amplify/ui-react';
import { ThreeCircles } from 'react-loader-spinner'
import { getLoaderComponent } from "../../Utils/Common.js";
import secureLocalStorage from "react-secure-storage";
import DataTable from 'react-data-table-component';



function Insights() {
    const [labelValue, setLabelValue] = useState("None");
    const [totalTopics, setTotalTopics] = useState(null);
    const [avgScore, setAvgScore] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [trendingTopic, setTrendingTopic] = useState(null);
    const [totalMessages, setTotalMessages] = useState(null);
    const [topicAndAvgRating, setTopicAndAvgRating] = useState();
    const [topCity, setTopCity] = useState();
    const [topState, setTopState] = useState();
    const [topCountry, setTopCountry] = useState();
    const [totalLocations, setTotalLocations] = useState();
    const loggedInUser = JSON.parse(secureLocalStorage.getItem("user"));

    // Columns for the table
    const columns = [
        {
            name: 'Topic',
            selector: row => row.topic,
            sortable: true,
        },
        {
            name: 'Avg. Sentiment',
            selector: row => row.avgRating,
            sortable: true,
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "18px",
            },
        },
        cells: {
            style: {
                fontSize: "16px",
            },
        },
    };

    // Get all the data from the database
    if (!isLoaded) {
        console.log("Getting data");
        getAllMessages(loggedInUser)
            .then((messages) => {
                Promise.all([
                    getTotalTopics(messages),
                    getAvgScore(messages),
                    getTrendingTopicPastWeek(messages),
                    getTotalMessages(messages),
                    getTopicAndAvgRating(messages),
                    getTopState(messages),
                    getTopCountry(messages),
                    getTopCity(messages),
                    getTotalLocations(messages)
                ])
                    .then(([totalTopics, avgScore, trendingTopic, totalMessages, topicAndAvgRating, topState, topCountry, topCity, totalLocations]) => {
                        setTotalTopics(totalTopics);
                        if (isNaN(avgScore)) {
                            setAvgScore("No Data");
                        } else {
                            setAvgScore(avgScore);
                        }
                        setTrendingTopic(trendingTopic);
                        setTotalMessages(totalMessages);
                        setTopicAndAvgRating(topicAndAvgRating);
                        setTopState(topState);
                        setTopCountry(topCountry);
                        setTopCity(topCity);
                        setTotalLocations(totalLocations);
                        setIsLoaded(true);
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
    }

    if (!isLoaded) {
        return (
            <div className="insights-page-safe-area">
                <div className="insights-page-title">
                    <h1>Insights</h1>
                </div>
                <div className="insights-page-container">
                    <ThreeCircles
                        visible={true}
                        height="100"
                        width="100"
                        color="#666666"
                        ariaLabel="three-circles-loading"
                        wrapperStyle={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "auto",
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                        wrapperClass=""
                        outerCircleColor="rgba(255, 0, 0, 0.0)"
                    />
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="insights-page-safe-area">
                <div className="insights-page-title">
                    <h1>Insights</h1>
                </div>
                {/*<div className="label-dropdown-container">
                <h3 className="label-dropdown-text">Label</h3>
                <DropdownList
                    value={labelValue}
                    defaultValue="None"
                    onChange={(nextValue) => setLabelValue(nextValue)}
                    data={["None", "Yellow", "Red", "Blue"]}
                />
    </div>*/}
                <div className="insights-page-container">

                    <div className="insights-card-small-flex-container">
                        <div className="insights-card-container-small">
                            <div className="insights-card-title">
                                <h2>Total Feedback</h2>
                            </div>
                            <div className="insights-card-value-container">
                                <div className="insights-card-value">
                                    {totalMessages === null ? <div style={{ marginTop: "30px" }}>{<div style={{ marginTop: "25px" }}>{getLoaderComponent()}</div>}</div> : <h2 className="insights-card-value-text">{totalMessages}</h2>}
                                </div>
                            </div>
                        </div>
                        <div className="insights-card-container-small">
                            <div className="insights-card-title">
                                <h2>Total Topics</h2>
                            </div>
                            <div className="insights-card-value-container">
                                <div className="insights-card-value">
                                    {totalTopics === null ? <div style={{ marginTop: "25px" }}>{getLoaderComponent()}</div> : <h2 className="insights-card-value-text">{totalTopics}</h2>}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="insights-card-small-flex-container">
                        <div className="insights-card-container-small">
                            <div className="insights-card-title">
                                <h2>Avg. Sentiment</h2>
                            </div>
                            <div className="insights-card-value-container">
                                <div className="insights-card-value">
                                    {avgScore == "No data" && avgScore !== null &&
                                        <div></div>
                                    }
                                    {avgScore === null &&
                                        <Loader size="large" />
                                    }
                                    {avgScore !== null && avgScore !== "No data" &&
                                        <h2 className="insights-card-value-text">{avgScore}</h2>}

                                </div>
                            </div>
                        </div>
                        <div className="insights-card-container-small">
                            <div className="insights-card-title">
                                <h2>Trending Topic</h2>
                            </div>
                            <div className="insights-card-value-container">
                                <div className="insights-card-value">
                                    {trendingTopic === null ? <div style={{ marginTop: "25px" }}>{getLoaderComponent()}</div> : <h2 className="insights-card-value-text-fitted">{trendingTopic}</h2>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="insights-card-container-pie">
                        <PieChart command="queryAllTopicCounts"></PieChart>
                    </div>

                    <div className="insights-card-container-medium">
                        <LineChart command="queryAllTopicCountsByDate" />
                    </div>
                    <div className="insights-card-container-pie">
                        <PieChart command="getSentimentCounts"></PieChart>
                    </div>
                    <div className="insights-card-container-medium">
                        <LineChart command="getAvgRatingByDate" />
                    </div>
                    <div className="insights-card-small-flex-container">
                        <div className="insights-card-container-small">
                            <div className="insights-card-title">
                                <h2>Top City</h2>
                            </div>
                            <div className="insights-card-value-container">
                                <div className="insights-card-value">
                                    {topCity === null ? <div style={{ marginTop: "25px" }}>{getLoaderComponent()}</div> : <h2 className="insights-card-value-text-fitted">{topCity}</h2>}
                                </div>
                            </div>
                        </div>
                        <div className="insights-card-container-small">
                            <div className="insights-card-title">
                                <h2>Top State</h2>
                            </div>
                            <div className="insights-card-value-container">
                                <div className="insights-card-value">
                                    {topState === null ? <div style={{ marginTop: "25px" }}>{getLoaderComponent()}</div> : <h2 className="insights-card-value-text-fitted">{topState}</h2>}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="insights-card-small-flex-container">
                        <div className="insights-card-container-small">
                            <div className="insights-card-title">
                                <h2>Top Country</h2>
                            </div>
                            <div className="insights-card-value-container">
                                <div className="insights-card-value">
                                    {topCountry === null ? <div style={{ marginTop: "25px" }}>{getLoaderComponent()}</div> : <h2 className="insights-card-value-text-fitted">{topCountry}</h2>}
                                </div>
                            </div>
                        </div>
                        <div className="insights-card-container-small">
                            <div className="insights-card-title">
                                <h2>Total Locations</h2>
                            </div>
                            <div className="insights-card-value-container">
                                <div className="insights-card-value">
                                    {totalLocations === null ? <div style={{ marginTop: "25px" }}>{getLoaderComponent()}</div> : <h2 className="insights-card-value-text">{totalLocations}</h2>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="data-table-container" >
                        <DataTable
                            title="Topics and Avg. Sentiment"
                            columns={columns}
                            data={topicAndAvgRating}
                            responsive
                            pagination
                            progressPending={!isLoaded}
                            defaultSortFieldId={2}
                            defaultSortAsc={false}
                            customStyles={customStyles}
                        />
                    </div>
                </div>
            </div >
        )
    }
}

export default Insights;