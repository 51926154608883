import React from "react";
import classes from './Home.module.css';
import Card from "./Card.js";
import profileIcon from "../../Images/person-circle-outline.svg";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";

//Props for cards
const cards = [
    { title: "Reviews", description: "All your reviews" },
    { title: "Insights", description: "See in depth data analyitics about your reviews" },
    //{ title: "Visual Map", description: "See your messages grouped by location" },
    { title: "Import", description: "Import your reviews", importDate: "12/12/2022", importTime: "12:03PM" },
    { title: "Settings", description: "Change your settings" }
];
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = { name: "", isLoaded: false, overlay: false };
    }

    /*
    * This function gets the user's name from the database
    */
    getUserName() {
        Auth.currentUserInfo()
            .then((data) => {
                this.setState({ name: data.attributes.name });
                this.setState({ isLoaded: true });
            }).catch(err => console.log(err));;
    }

    /*
    * This function is called when the component is first loaded
    */
    componentDidMount() {
        this.getUserName();
        let a;
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            a = true;
            this.setState({ overlay: true });
        } else {
            a = false;
            this.setState({ overlay: false });
        }
    }

    render() {
        const name = this.state.name;
        return (
            <>
                {this.state.overlay ? <div className={classes.overlay}>
                    <h1 className={classes.overlayText}>This website is not currently optimized for mobile devices. Please use a desktop or laptop.</h1>
                    <div onClick={() => this.setState({ overlay: false })}><u>Dismiss</u></div>
                </div> : null}

                <div className={classes.homePage}>

                    <div className={classes.homePageRightSide}>

                        {this.state.isLoaded ? <h1 className={classes.welcomeMessage}>Welcome {name}</h1> : <h1 className={classes.welcomeMessageNone}></h1>}
                        <div className={classes.cardsSection}>
                            {cards.map((card, index) => {
                                return <Card {...card} key={index} />
                            })}
                        </div>
                    </div>
                    <div className={classes.profileSection}>
                        <Link to="/Settings">
                            <img className={classes.profilePicture} src={profileIcon} alt="profilePicture" />
                        </Link>
                    </div>
                </div >

            </>
        );
    }
}

export default Home;