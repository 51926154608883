import { Auth } from 'aws-amplify';

export async function getCurrentUserID() {
    const user = await Auth.currentUserInfo();
    return user.username;
}

export function getDateDiff(date_string) {
    ///
    // Returns difference between current date and a given date string
    ///
    const todays_date = new Date()
    
    var comparison_date = new Date(date_string)
    var diff = todays_date - comparison_date

    var days = diff/86400000

    // Return hours if days <= 1
    if (days <= 1) { return String(Math.round(days*24))+"h"}

    return String(Math.round(days))+"d"
}

