import React from 'react';
import { useNavigate } from "react-router-dom";
import './ImportCard.css';
import CSVImage from "../../../Images/csv.png";
import ContactFormImage from "../../../Images/form.png";
import EmailImage from "../../../Images/email.png";

function ImportCard({ title }) {

    const navigate = useNavigate();

    function navigateTo(navigate, cardTitle) {
        // Navigate to the correct page
        switch (cardTitle) {
            case "CSV":
                navigate("/CSVImport");
                break;
            case "Contact Form":
                navigate("/ContactForm-Import");
                break;
            case "Email":
                navigate("/EmailImport");
                break;
            default:
                break;
        }
    }

    /**
     * 
     * @param {*} title 
     * @returns Image for the card
     */
    function getIMG(title) {
        if (title === "CSV") {
            return CSVImage;
        }
        else if (title === "Contact Form") {
            return ContactFormImage;
        }
        else if (title === "Email") {
            return EmailImage;
        }
    }

    return (
        <>
            <div className="import-card-container" onClick={() => {
                navigateTo(navigate, title);
            }}>
                <div className='import-card-image-container'>
                    <img className="import-card-image" src={getIMG(title)} alt={title} />
                </div>
                <div className="import-card-text-container">
                    <h1 className="import-card-text">{title}</h1>
                </div>
            </div>
        </>
    );
}
export default ImportCard;