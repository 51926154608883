import React from 'react';
import './ImportPage.css';
import Sidebar from "../../Components/SidebarNav/Sidebar";
import ImportCard from "./Components/ImportCard";

const cards = [
    { title: "Contact Form" },
    { title: "Email", importDateTime: "12/12/2022 12:03PM" },
    { title: "CSV", importDateTime: "12/12/2022 12:03PM" },
];

function ImportPage() {
    return (
        <>
            <div className="importPageSafeArea">
                <div className="import-title-container">
                    <h1 className="import-title">Import</h1>
                </div>
                <div className="import-container">

                    {cards.map((card, index) => {
                        return <ImportCard {...card} key={index} />
                    })}
                </div>
            </div>
        </>
    );
}
export default ImportPage;