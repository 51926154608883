import React from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import axios from 'axios';
import { useState } from 'react';
import { getCurrentUserID } from "../VisualMapPage/Helpers/GetData.js";
import { TextField, Button } from '@aws-amplify/ui-react';

import './ContactFormGenerator.css';

function ContactFormGenerator() {
    const [isLoading, setLoading] = useState(true);
    const [messageInput, setMessageInput] = useState("");

    // Amplify Refyne Customer ID - for authenticating queries
    const [refyneCustomerId, setrefyneCustomerId] = React.useState(null);

    React.useEffect(() => {

        async function getRefyneCustomerId() {
            const result = await getCurrentUserID();
            setrefyneCustomerId(result);
            console.log(result);
            setLoading(false);

        }

        getRefyneCustomerId()
    }, [refyneCustomerId]);


    if (isLoading) {
        return (
            <>

                <div className="contact-form-generator-page">
                    <div className="contact-form-generator-page-title">
                        <h1>Contact Form</h1>

                    </div>
                    <p>Loading...</p>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="contact-form-generator-page">
                <div className="contact-form-generator-page-title">
                    <h1>Contact Form</h1>
                </div>


                <div className="contact-form-generator-page-container">
                    <TextField
                        placeholder="Baggins"
                        label="Your personal contact form link"
                        defaultValue={"https://refyne-ai.com/contact/" + refyneCustomerId}
                        descriptiveText="Copy and share this link with customers."
                        isReadOnly={true}
                    />
                    <Button
                        size="small"
                        variation="link"
                        loadingText=""
                        onClick={() => {
                            window.open('contact/' + refyneCustomerId);
                        }}
                        ariaLabel=""
                    >
                        Open contact form in new tab
                    </Button>
                </div>

            </div>
        </>
    )
}

export default ContactFormGenerator;