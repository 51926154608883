import { API, Auth } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';
import secureLocalStorage from "react-secure-storage";

/*
* This function gets the current user's ID
* @return {Promise} - A promise that resolves to the user's ID
* */
export async function getCurrentUserID() {
    const user = await Auth.currentUserInfo();
    return user.username;
}

/*
* This function queries all the messages from the database
* @return {Promise} - A promise that resolves to the messages
* */
export async function queryAllUserMessages() {
    const promise1 = await new Promise(async (resolve, reject) => {
        const loggedInUser = JSON.parse(secureLocalStorage.getItem("user"));
        if (loggedInUser) {
            resolve(loggedInUser.username);
        }
        else {
            reject("No user is logged in");
        }


    });
    if (promise1) {
        const promise2 = await new Promise((resolve, reject) => {
            const variables = {
                customer_id: promise1,
                sortDirection: 'DESC',
                filter: {
                    hidden: {
                        eq: "false"
                    }

                }
            };


            API.graphql({
                query: queries.messages_by_date_and_customerid,
                variables: variables
            }).then((messages) => {
                var messagesJSON = []
                var parsedLabel = null;
                console.log(messages)

                for (var i = 0; i < messages.data.messages_by_date_and_customerid.items.length; i++) {

                    if (messages.data.messages_by_date_and_customerid.items[i].label !== "") {
                        parsedLabel = JSON.parse(messages.data.messages_by_date_and_customerid.items[i].label);
                    }
                    else {
                        parsedLabel = null;
                    }

                    messagesJSON.push({
                        "messageID": messages.data.messages_by_date_and_customerid.items[i].message_id,
                        "body": messages.data.messages_by_date_and_customerid.items[i].message_string,
                        "timestamp": messages.data.messages_by_date_and_customerid.items[i].timestamp,
                        "email": messages.data.messages_by_date_and_customerid.items[i].email,
                        "topic": messages.data.messages_by_date_and_customerid.items[i].topic,
                        "rating": messages.data.messages_by_date_and_customerid.items[i].rating,
                        "city": messages.data.messages_by_date_and_customerid.items[i].city,
                        "state": messages.data.messages_by_date_and_customerid.items[i].state,
                        "country": messages.data.messages_by_date_and_customerid.items[i].country,
                        "label": parsedLabel,
                        "notification": true,
                        "isSelected": false,
                        "isHidden": false
                    });
                }

                resolve(messagesJSON);

            }).catch(err => console.log(err));

        });
        return promise2
    }
}

/*
* This function deletes the message from the database
* */
export async function softDeleteMessage(id) {
    const messageDetails = {
        message_id: id,
        hidden: "true"
    };

    await API.graphql({
        query: mutations.updateMessage,
        variables: { input: messageDetails }
    }).then((message) => { console.log("Deleted message.") }).catch(err => console.log(err));
}

/*
 * This function returns the selected message
 * @param {array} messages - the array of messages
 * @param {string} id - the id of the selected message
 * */
export async function addLabelToDB(messageID, label) {
    try {
        const messageDetails = {
            message_id: messageID,
            label: JSON.stringify({ "color": label.color, "title": label.title })
        }

        await API.graphql({
            query: mutations.updateMessage,
            variables: { input: messageDetails }
        }).then((message) => { console.log("Added label to DB.") }).catch(err => console.log(err));
    } catch (err) {
        console.log(err);
    }
}


/*
 * This function returns the selected message    
 * @param {string} id - the id of the selected message
 */
export async function removeLabelFromDB(messageID) {
    const messageDetails = {
        message_id: messageID,
        label: ""
    }

    await API.graphql({
        query: mutations.updateMessage,
        variables: { input: messageDetails }
    }).then((message) => { console.log("Removed Label") }).catch(err => console.log(err));
}