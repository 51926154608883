import React from 'react'
import './ContactForm.css'
import RefyneLogo from '../../Images/Refyne-Logo.jpeg'

const ContactForm = () => {
  const [formStatus, setFormStatus] = React.useState('Send')
  const onSubmit = (e) => {
    e.preventDefault()
    setFormStatus('Submitting...')
    const { email } = e.target.elements
    let conFom = {
      email: email.value,
    }
    console.log(conFom)
  }
  return (
    <div className="window">
      <div className="container window-form">
        <h2 className="text-refyne">Be First To Get Notified About Refyne</h2>


      </div>
    </div>
  )
}
export default ContactForm