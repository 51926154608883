import React, { useState } from "react";
import "./EmailImportPage.css";
import Sidebar from "../../Components/SidebarNav/Sidebar";
import { validateEmail } from "../../Utils/Validator.js";
import API_URLS from "../../config";
import axios from 'axios';
import Tooltip from "./Components/Tooltip";
import secureLocalStorage from "react-secure-storage";
import DataTable from 'react-data-table-component';


function EmailImportPage() {
  const [serverAddressInput, setServerAddressInput] = useState(""); // IMAP Server Address
  const [emailInput, setEmailInput] = useState(""); // Email
  const [passwordInput, setPasswordInput] = useState(""); // Password
  const [folderNameInput, setFolderNameInput] = useState("INBOX"); // Email folder name
  const [searchInput, setSearchInput] = useState(""); // Search query
  const [emailData, setEmailData] = useState([]);
  const [didSubmit, setDidSubmit] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  // DataTable
  const columns = [
    { name: 'Subject', selector: row => row.subject, sortable: true },
    { name: 'Email Address', selector: row => row.sender, sortable: true },
    { name: 'Time Sent', selector: row => row.timeSent, sortable: true },
  ];

  const ExpandedComponent = ({ data }) => <div style={{ "paddingLeft": "2%", "textAlign": "left" }} > {data.body}</div >

  function canRetrieve() {
    return serverAddressInput.length > 0 && emailInput.length > 0 && passwordInput.length > 0
  }

  /*
  * Send the emails to the message processor API Gateway
  * @returns {void}
  * */
  function submitEmails(e) {
    const loggedInUser = JSON.parse(secureLocalStorage.getItem("user"));
    e.preventDefault();
    if (didSubmit || !canSubmit) {
      return;
    }
    else {
      let serverUrl = String(serverAddressInput)
      let username = String(emailInput)
      let password = String(passwordInput)
      let mailbox = String(folderNameInput)
      let search = String(searchInput)
      let headers = {
        'customerid': String(loggedInUser.username),
        'customersecret': String(loggedInUser.customersecret),
        'Content-Type': 'application/json'
      }
      let body = JSON.stringify({
        "serverUrl": serverUrl,
        "username": username,
        "password": password,
        "mailbox": mailbox,
        "search": search,
        "customerId": String(loggedInUser.username),
        "customerSecret": String(loggedInUser.customersecret)
      })

      axios.post(API_URLS.submitEmailLambda, body, { headers: headers }).then((res) => {
        setDidSubmit(true)
      })
        .catch((error) => {
          console.log(error);
        });
      return;
    }
  }

  function retrieveEmails(e) {
    e.preventDefault();
    let serverUrl = String(serverAddressInput)
    let username = String(emailInput)
    let password = String(passwordInput)
    let mailbox = String(folderNameInput)
    let search = String(searchInput)
    let headers = {
      'Content-Type': 'application/json'
    }
    let body = JSON.stringify({
      "serverUrl": serverUrl,
      "username": username,
      "password": password,
      "mailbox": mailbox,
      "search": search,
    })

    axios.post(API_URLS.retrieveEmailLambda, body, { headers: headers }).then((res) => {
      let emails = res.data['emails'];
      setEmailData(emails)
      setCanSubmit(true)
    })
      .catch((error) => {
        console.log(error);
      });
    return;
  }

  return (
    <>
      <div className="emailImportPage">
        <div className="homePageSafeArea">
          <Sidebar></Sidebar>
          <h1 className="titleMessage">Email Import</h1>
          <div className="flexContainerEmail">
            <div className="emailImportForm">
              <form>
                <label className="inputLabel">IMAP Server Address</label>
                <Tooltip tooltipText="What is this?" mouseOverText="IMAP (Internet Message Access Protocol) is a widely used email protocol that allows users to access and manage their email messages on a remote mail server. Navigate to the settings of your email client to find this. It may be labeled as 'IMAP Server', 'Incoming Server', or 'Server Address'.">
                </Tooltip>
                <br />
                <input type="text" value={serverAddressInput} className="form-input" aria-describedby="imapHelp" placeholder="imap.domain.com" onChange={(e) => setServerAddressInput(e.target.value)} />
                <br />
                <label className="inputLabel">Email address</label>
                <br />
                <input type="email" value={emailInput} className="form-input" aria-describedby="emailHelp" placeholder="Email" onChange={(e) => setEmailInput(e.target.value)} />
                {emailInput.length > 0 && !validateEmail(emailInput) && (
                  <label style={{ color: "rgb(255, 0, 0)", display: "block" }}>
                    Please enter a valid <em>email</em>
                  </label>
                )}
                <br />
                <label>Email password</label>
                {/* TODO: create a proper popup and provide more detailed instructions for gmail users*/}
                <a href="https://support.google.com/mail/answer/185833?hl=en" target="_blank" >
                  <Tooltip tooltipText="Gmail Instructions" mouseOverText="Due to Gmail security settings, you must create an app password with email access to import messages from Gmail. Click to learn more.">
                  </Tooltip>
                </a>
                <br />
                <input type="password" value={passwordInput} className="form-input" placeholder="Password" onChange={(e) => setPasswordInput(e.target.value)} />
                <br />
                {/* TODO: error message popup upon retrieval failue */}
              </form>
            </div>
            <div className="emailImportFormRight">
              <form>
                <label className="inputLabel">Enter email folder name</label>
                <br />
                <input type="text" value={folderNameInput} className="form-input" aria-describedby="emailFolder" placeholder="Email folder" onChange={(e) => setFolderNameInput(e.target.value)} />
                <br />
                <label className="inputLabel">Search</label>
                <br />
                <input type="search" value={searchInput} className="form-input" aria-describedby="emailHelp" placeholder="Search" onChange={(e) => setSearchInput(e.target.value)} />
              </form>
              <button className="email-button" onClick={e => retrieveEmails(e)} disabled={!canRetrieve()}
              >Retrieve Emails</button>
              <br />
              <button className="email-button" onClick={e => submitEmails(e)} disabled={!canSubmit}
              >Submit Emails</button>
            </div>
            <div className="import-csv-bottom-container">
              <DataTable title="Results" columns={columns} data={emailData} expandableRowsComponent={ExpandedComponent} dense pagination striped highlightOnHover responsive expandableRows expandOnRowClicked />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailImportPage;