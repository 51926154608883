import React from 'react';
import '../LandingPage.css';
import './Contact.css'
import Navbar from '../Navbar';

const Contact = () => {
  const [formStatus, setFormStatus] = React.useState('Send')
  const onSubmit = (e) => {
    e.preventDefault()
    setFormStatus('Submitting...')
    const { name, email, message } = e.target.elements
    let conFom = {
      name: name.value,
      email: email.value,
      message: message.value,
    }
    console.log(conFom)
  }
  return (

    <>
      <Navbar />
      <div className="container contact-window-form">
        <form onSubmit={onSubmit}>
          <div className="contact-form">
            <label className="contact-form-label" htmlFor="name">
              Name
            </label>
            <input className="contact-form-control" type="text" id="name" required />
          </div>
          <div className="contact-form">
            <label className="contact-form-label" htmlFor="email">
              Email
            </label>
            <input className="contact-form-control" type="email" id="email" required />
          </div>
          <div className="contact-form">
            <label className="contact-form-label" htmlFor="message">
              Message
            </label>
            <textarea className="contact-form-control" id="message" required />
          </div>
          <button className="btn btn-form-submit" type="submit">
            {formStatus}
          </button>
        </form>
        <br></br>
        <a href="mailto:admin@refyne-ai.com">Send Email</a>
      </div>
    </>
  )
}
export default Contact