/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessage = /* GraphQL */ `
  query GetMessage($message_id: String!) {
    getMessage(message_id: $message_id) {
      message_id
      customer_id
      first_name
      last_name
      message_string
      message_hash
      email
      state
      city
      country
      topic
      rating
      timestamp
      hidden
      lat
      long
      createdAt
      updatedAt
      label
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $message_id: String
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMessages(
      message_id: $message_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        message_id
        customer_id
        first_name
        last_name
        message_string
        message_hash
        email
        state
        city
        country
        topic
        rating
        timestamp
        hidden
        lat
        long
        createdAt
        updatedAt
        label
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($customer_id: String!) {
    getCustomer(customer_id: $customer_id) {
      customer_id
      first_name
      last_name
      email
      address
      birthdate
      industry
      company_name
      created_at
      updated_at
      secret
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $customer_id: String
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomers(
      customer_id: $customer_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        customer_id
        first_name
        last_name
        email
        address
        birthdate
        industry
        company_name
        created_at
        updated_at
        secret
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerSubscription = /* GraphQL */ `
  query GetCustomerSubscription($customer_id: String!) {
    getCustomerSubscription(customer_id: $customer_id) {
      customer_id
      status
      subscription_tier
      stripeID
      totalTokens
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerSubscriptions = /* GraphQL */ `
  query ListCustomerSubscriptions(
    $customer_id: String
    $filter: ModelCustomerSubscriptionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomerSubscriptions(
      customer_id: $customer_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        customer_id
        status
        subscription_tier
        stripeID
        totalTokens
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContactFormInfo = /* GraphQL */ `
  query GetContactFormInfo($customer_id: String!, $form_id: Int!) {
    getContactFormInfo(customer_id: $customer_id, form_id: $form_id) {
      customer_id
      form_id
      title
      fields
      createdAt
      updatedAt
    }
  }
`;
export const listContactFormInfos = /* GraphQL */ `
  query ListContactFormInfos(
    $customer_id: String
    $form_id: ModelIntKeyConditionInput
    $filter: ModelContactFormInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContactFormInfos(
      customer_id: $customer_id
      form_id: $form_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        customer_id
        form_id
        title
        fields
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubscriptionInfo = /* GraphQL */ `
  query GetSubscriptionInfo($subscription_tier: Int!) {
    getSubscriptionInfo(subscription_tier: $subscription_tier) {
      subscription_tier
      cost
      createdAt
      updatedAt
    }
  }
`;
export const listSubscriptionInfos = /* GraphQL */ `
  query ListSubscriptionInfos(
    $subscription_tier: Int
    $filter: ModelSubscriptionInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubscriptionInfos(
      subscription_tier: $subscription_tier
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        subscription_tier
        cost
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerEmail = /* GraphQL */ `
  query GetCustomerEmail($customer_id: String!) {
    getCustomerEmail(customer_id: $customer_id) {
      customer_id
      email
      imap_address
      folder
      search
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerEmails = /* GraphQL */ `
  query ListCustomerEmails(
    $customer_id: String
    $filter: ModelCustomerEmailFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomerEmails(
      customer_id: $customer_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        customer_id
        email
        imap_address
        folder
        search
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messages_by_date_and_customerid = /* GraphQL */ `
  query Messages_by_date_and_customerid(
    $customer_id: String!
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messages_by_date_and_customerid(
      customer_id: $customer_id
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        message_id
        customer_id
        first_name
        last_name
        message_string
        message_hash
        email
        state
        city
        country
        topic
        rating
        timestamp
        hidden
        lat
        long
        createdAt
        updatedAt
        label
      }
      nextToken
    }
  }
`;
export const posted_at_by_date = /* GraphQL */ `
  query Posted_at_by_date(
    $hidden: String!
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    posted_at_by_date(
      hidden: $hidden
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        message_id
        customer_id
        first_name
        last_name
        message_string
        message_hash
        email
        state
        city
        country
        topic
        rating
        timestamp
        hidden
        lat
        long
        createdAt
        updatedAt
        label
      }
      nextToken
    }
  }
`;
