import React, { useState, useEffect } from "react";
import "./Messages.css";
import MessagePreview from "./Components/MessagePreview";
import Message from "./Components/Message";
import Label from "./Components/Label";
import { getLabels, returnSelected, filterByLabel, searchByBody, updateMessageLabel } from "./Helpers/MessageListHelpers.js";
import { queryAllUserMessages } from "./Helpers/getData";
import { Loader } from '@aws-amplify/ui-react';

function Messages() {
    // Search
    const [searchInput, setSearchInput] = useState("");

    // Messages
    const [messages, setMessages] = useState(undefined);
    const [currentMessage, setCurrentMessage] = useState(undefined);
    const [didLoad, setDidLoad] = useState(false);

    // Labels
    const [labelInput, setLabelInput] = useState("None");
    const [labels, setLabels] = useState([]);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [color, setColor] = useState("#fff");
    const [newLabelName, setNewLabelName] = useState("");


    useEffect(() => {
        if (!didLoad) {
            getMessages();
            setDidLoad(true);
        }
    }, []);

    /*
    *  This function gets all the messages from the database and asigns them to states
    */
    async function getMessages() {
        await queryAllUserMessages().then((data) => {
            // debug print messages
            console.log(data)
            if (data.length === 0) {
                setMessages(data);
                return;
            }
            setMessages(data);
            setLabels(getLabels(data));
            setCurrentMessage(returnSelected(data, data[0].messageID));
        }).catch(err => console.log(err));
    }


    function updateOnDelete(messageID) {
        let newMessages = [...messages];
        console.log(messageID)
        console.log(newMessages)
        let index = newMessages.findIndex((message) => message.messageID === messageID);
        newMessages.splice(index, 1);
        setMessages(newMessages);
        setCurrentMessage(newMessages[index]);
    }
    /*
    *   This function updates the selected message and sets the notification to false
    * @param {string} id - The message ID
    */
    function updateSelected(id) {
        let newMessages = [...messages];

        for (let i = 0; i < messages.length; i++) {
            if (newMessages[i].isSelected === true) {
                newMessages[i].isSelected = false;
                newMessages[i].notification = false;
                break;
            }
        }
        for (let i = 0; i < messages.length; i++) {
            if (newMessages[i].messageID === id) {
                newMessages[i].isSelected = true;
                break;
            }
        }

        setCurrentMessage(returnSelected(newMessages, id));
        setMessages(newMessages);
    }

    /*
    *   This function updates the search input and updates the messages list
    * @param {string} input - The search input
    */
    function updateSearchInput(input) {
        setSearchInput(input);
        if (input === "") {
            setMessages(searchByBody(messages, ""));
        }
        else {
            let newMessages = searchByBody(filterByLabel(messages, labelInput), input);
            setMessages(newMessages);
        }

    }

    /*
    *   This function updates the message list when a label is added
    * @param {string} messageID - The message ID
    * @param {string} title - The title of the label
    * @param {string} color - The color of the label
    * @returns {array} labels - The list of labels
    */
    function updateOnAddLabel(messageID, title, color) {

        let newMessages = updateMessageLabel(messages, messageID, title, color);

        setMessages(newMessages);
        let labels = getLabels(newMessages)
        setLabels(labels);
        console.log(labels)
        return labels
    }

    /*
    *   This function updates the message list when a label is removed 
    * @param {string} messageID - The message ID
    * @returns {array} labels - The list of labels 
    */
    function updateOnRemoveLabel(messageID) {

        let newMessages = updateMessageLabel(messages, messageID, undefined, undefined);

        setMessages(newMessages);
        let labels = getLabels(newMessages)
        setLabels(labels);
        console.log(labels)
        return labels
    }

    /*
    *   This function updates the label input and updates the messages list
    * @param {string} label - The label to filter by
    */
    function updateLabelSelection(label) {
        setLabelInput(label);
        let newMessages = filterByLabel(searchByBody(messages, searchInput), label);
        setMessages(newMessages);

    }

    return (
        <div className="messages-page-safe-area">
            <div className="inbox-title-container">
                <h1>Messages</h1>
            </div>
            <div className="messages-page-container">
                <div className="folders">
                    <div className="top-container">

                    </div>

                    <div className="labels-container">
                        <div className="label-title-container" style={{ marginLeft: "4px" }}>
                            <h3 className="label-title">Labels</h3>
                        </div>
                        {labels.map((e, idx) => {
                            return (<Label key={idx} label={e} isSelected={labelInput} updateSelected={updateLabelSelection} />)
                        }
                        )}
                    </div>
                </div>
                <div className="messages">
                    <div className="top-container">
                        <div className="search-bar-container">
                            <input type="text" value={searchInput} className="search-bar" placeholder="Search" onChange={(e) => updateSearchInput(e.target.value)} />
                        </div>
                        <hr className="hr-line-full"></hr>
                    </div>
                    <div className="message-list">
                        {messages === undefined ? <Loader size="large" /> :
                            messages.map((e, idx) => {
                                return (<MessagePreview key={idx} messageID={e.messageID} title={e.title} body={e.body} timestamp={e.timestamp} notification={e.notification} isSelected={e.isSelected} isHidden={e.isHidden} label={e.label} updateSelected={updateSelected} />)
                            })}
                    </div>
                </div>
                <div className="message-container">
                    {currentMessage === undefined ? <div><p>Select a message to view</p></div> : <Message messageID={currentMessage.messageID} timestamp={currentMessage.timestamp} messageTitle={currentMessage.title} messageBody={currentMessage.body} email={currentMessage.email} city={currentMessage.city} state={currentMessage.state} country={currentMessage.country} topic={currentMessage.topic} rating={currentMessage.rating} label={currentMessage.label} allLabels={labels} updateOnAddLabel={updateOnAddLabel} updateOnRemoveLabel={updateOnRemoveLabel} updateOnDelete={updateOnDelete} />}
                </div>
            </div>
        </div >
    )
}

export default Messages;