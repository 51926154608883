import { API, Auth } from 'aws-amplify';
import * as queries from '../../../graphql/queries';

export async function getAllMessages(userInfo) {
    const userName = userInfo.username;
    var variables = {
        filter: { customer_id: { eq: userName }, hidden: { eq: "false" } }
    };
    const messages = await API.graphql({
        query: queries.listMessages,
        variables: variables
    })

    let nextToken = messages.data.listMessages.nextToken;
    let messageArray = messages.data.listMessages.items;

    while (nextToken != null) {

        let newMessages = await API.graphql({
            query: queries.listMessages,
            variables: {
                filter: { customer_id: { eq: userName }, hidden: { eq: "false" } },
                nextToken: nextToken
            }
        })
        messageArray.push(...newMessages.data.listMessages.items);
        nextToken = newMessages.data.listMessages.nextToken;
    }
    return messageArray;
}

export async function getCurrentUserID() {
    const user = await Auth.currentUserInfo();
    return user.username;
}

export function getChartProps(data, labels) {
    let dataProps = {
        labels,
        datasets: [
            {
                data: data
            },
        ],
    };

    return dataProps
}

/*
*   This function queries the database for the score and topic data
*/
export function queryScoreAndTopic() {

}

/*
* This function gets the total number of topics
*/
export async function getTotalTopics(messages) {
    let foundTopics = {};
    for (let i = 0; i < messages.length; i++) {
        if (messages[i].topic == null) {
            continue;
        }
        for (let j = 0; j < messages[i].topic.length; j++) {
            if (messages[i].topic[j] in foundTopics) {
                foundTopics[messages[i].topic[j]] += 1;
            } else {
                foundTopics[messages[i].topic[j]] = 1;
            }
        }
    }
    let totalTopics = Array.from(Object.keys(foundTopics)).length;
    return totalTopics
}

/*
*   This function queries the database for the top 5 topic counts for the past 30 days
*/
export async function queryAllTopicCounts() {
    const userName = await getCurrentUserID();
    const variables = {
        filter: { customer_id: { eq: userName } },
        hidden: false
    };
    const messages = await API.graphql({
        query: queries.posted_at_by_date,
        variables: variables
    });
    let foundTopics = {};
    for (let i = 0; i < messages.data.posted_at_by_date.items.length; i++) {
        if (messages.data.posted_at_by_date.items[i].topic == null) {
            continue;
        }
        for (let j = 0; j < messages.data.posted_at_by_date.items[i].topic.length; j++) {
            if (messages.data.posted_at_by_date.items[i].topic[j] in foundTopics) {
                foundTopics[messages.data.posted_at_by_date.items[i].topic[j]] += 1;
            } else {
                foundTopics[messages.data.posted_at_by_date.items[i].topic[j]] = 1;
            }
        }
    }
    let keys = Array.from(Object.keys(foundTopics));
    let values = Array.from(Object.values(foundTopics));
    return getChartProps(values, keys)
}

export async function queryAllTopicCountsByDate(date1, date2, messages) {
    let foundItems = {};

    for (let i = 0; i < messages.length; i++) {

        if (new Date(messages[i].timestamp).toDateString() in foundItems && new Date(messages[i].timestamp) >= date1 && new Date(messages[i].timestamp) <= date2) {
            foundItems[new Date(messages[i].timestamp).toDateString()] += 1;
        } else {
            if (new Date(messages[i].timestamp) >= date1 && new Date(messages[i].timestamp) <= date2) {
                foundItems[new Date(messages[i].timestamp).toDateString()] = 1;
            }

        }
    }

    let keys = Array.from(Object.keys(foundItems));
    let values = Array.from(Object.values(foundItems));
    return getChartProps(values, keys)
}

export async function getAvgRatingByDate(date1, date2, messages) {
    let totalRatingByDate = {};
    let totalMessagesByDate = {};

    for (let i = 0; i < messages.length; i++) {
        if (new Date(messages[i].timestamp).toDateString() in totalMessagesByDate && new Date(messages[i].timestamp) >= date1 && new Date(messages[i].timestamp) <= date2) {
            totalRatingByDate[new Date(messages[i].timestamp).toDateString()] += parseInt(messages[i].rating);
            totalMessagesByDate[new Date(messages[i].timestamp).toDateString()] += 1;

        } else {
            if (new Date(messages[i].timestamp) >= date1 && new Date(messages[i].timestamp) <= date2) {
                totalRatingByDate[new Date(messages[i].timestamp).toDateString()] = parseInt(messages[i].rating);
                totalMessagesByDate[new Date(messages[i].timestamp).toDateString()] = 1;
            }
        }

    }

    for (let i = 0; i < Object.keys(totalRatingByDate).length; i++) {
        totalRatingByDate[Object.keys(totalRatingByDate)[i]] = totalRatingByDate[Object.keys(totalRatingByDate)[i]] / totalMessagesByDate[Object.keys(totalRatingByDate)[i]];
    }

    let keys = Array.from(Object.keys(totalRatingByDate));
    let values = Array.from(Object.values(totalRatingByDate));
    console.log(keys)
    console.log(values)
    return getChartProps(values, keys)
}

export async function getTotalMessages(messages) {

    return messages.length;
}

export async function getTopLocation(userInfo) {
    const userName = userInfo.username;
    const variables = {
        filter: { customer_id: { eq: userName } },
        hidden: false
    };
    const messages = await API.graphql({
        query: queries.posted_at_by_date,
        variables: variables
    });
    let foundLocations = {};
    for (let i = 0; i < messages.data.posted_at_by_date.items.length; i++) {
        if (messages.data.posted_at_by_date.items[i].state in foundLocations) {
            foundLocations[messages.data.posted_at_by_date.items[i].state] += 1;
        } else if (messages.data.posted_at_by_date.items[i].state !== "" && messages.data.posted_at_by_date.items[i].state !== null) {
            foundLocations[messages.data.posted_at_by_date.items[i].state] = 1;
        }
        if (messages.data.posted_at_by_date.items[i].city in foundLocations) {
            foundLocations[messages.data.posted_at_by_date.items[i].city] += 1;
        } else if (messages.data.posted_at_by_date.items[i].city !== "" && messages.data.posted_at_by_date.items[i].state !== null) {
            foundLocations[messages.data.posted_at_by_date.items[i].city] = 1;
        }
    }
    let topLocation = "";
    let topLocationCount = 0;
    for (let i = 0; i < Object.keys(foundLocations).length; i++) {
        if (foundLocations[Object.keys(foundLocations)[i]] > topLocationCount) {
            topLocationCount = foundLocations[Object.keys(foundLocations)[i]];
            topLocation = Object.keys(foundLocations)[i];
        }
    }
    return topLocation;

}

export async function getTrendingTopicPastWeek(messages) {
    let foundTopics = {};
    for (let i = 0; i < messages.length; i++) {
        if (messages[i].topic !== null) {
            for (let j = 0; j < messages[i].topic.length; j++) {
                if (messages[i].topic[j] in foundTopics) {
                    foundTopics[messages[i].topic[j]] += 1;
                }
                else {
                    foundTopics[messages[i].topic[j]] = 1;
                }
            }
        }

    }

    let topTopic = "";
    let topTopicCount = 0;
    for (let i = 0; i < Object.keys(foundTopics).length; i++) {
        if (foundTopics[Object.keys(foundTopics)[i]] > topTopicCount) {
            topTopicCount = foundTopics[Object.keys(foundTopics)[i]];
            topTopic = Object.keys(foundTopics)[i];
        }
    }
    return topTopic;
}

export async function getTotalLocations(messages) {
    let foundLocations = {};
    for (let i = 0; i < messages.length; i++) {
        if (messages[i].state in foundLocations) {
            foundLocations[messages[i].state] += 1;
        } else if (messages[i].state !== "" && messages[i].state !== null) {
            foundLocations[messages[i].state] = 1;
        }
        if (messages[i].city in foundLocations) {
            foundLocations[messages[i].city] += 1;
        } else if (messages[i].city !== "" && messages[i].state !== null) {
            foundLocations[messages[i].city] = 1;
        }
    }
    return Object.keys(foundLocations).length;
}

export async function getTopCity(messages) {

    let foundLocations = {};
    for (let i = 0; i < messages.length; i++) {
        if (messages[i].city in foundLocations) {
            foundLocations[messages[i].city] += 1;
        } else if (messages[i].city !== "" && messages[i].state !== null) {
            foundLocations[messages[i].city] = 1;
        }
    }
    let topLocation = "";
    let topLocationCount = 0;
    for (let i = 0; i < Object.keys(foundLocations).length; i++) {
        if (foundLocations[Object.keys(foundLocations)[i]] > topLocationCount) {
            topLocationCount = foundLocations[Object.keys(foundLocations)[i]];
            topLocation = Object.keys(foundLocations)[i];
        }
    }
    return topLocation;

}
export async function getTopState(messages) {
    let foundLocations = {};
    for (let i = 0; i < messages.length; i++) {
        if (messages[i].state in foundLocations) {
            foundLocations[messages[i].state] += 1;
        } else if (messages[i].state !== "" && messages[i].state !== null) {
            foundLocations[messages[i].state] = 1;
        }
    }
    let topLocation = "";
    let topLocationCount = 0;
    for (let i = 0; i < Object.keys(foundLocations).length; i++) {
        if (foundLocations[Object.keys(foundLocations)[i]] > topLocationCount) {
            topLocationCount = foundLocations[Object.keys(foundLocations)[i]];
            topLocation = Object.keys(foundLocations)[i];
        }
    }
    return topLocation;
}

export async function getTopCountry(messages) {
    let foundLocations = {};
    for (let i = 0; i < messages.length; i++) {
        if (messages[i].country in foundLocations) {
            foundLocations[messages[i].country] += 1;
        } else if (messages[i].country !== "" && messages[i].country !== null) {
            foundLocations[messages[i].country] = 1;
        }
    }
    let topLocation = "";
    let topLocationCount = 0;
    for (let i = 0; i < Object.keys(foundLocations).length; i++) {
        if (foundLocations[Object.keys(foundLocations)[i]] > topLocationCount) {
            topLocationCount = foundLocations[Object.keys(foundLocations)[i]];
            topLocation = Object.keys(foundLocations)[i];
        }
    }
    return topLocation;
}

/*
 * Returns all topics and their average rating
 * @param userInfo: the user's information
 * @return: JSON with the topics and the average rating for each topic
    */
export async function getTopicAndAvgRating(messages) {
    let totalRatingByTopic = {};
    let totalMessagesByTopic = {};
    for (let i = 0; i < messages.length; i++) {
        for (let j = 0; j < messages[i].topic.length; j++) {
            if (messages[i].topic[j] in totalRatingByTopic) {
                totalRatingByTopic[messages[i].topic[j]] += parseInt(messages[i].rating);
                totalMessagesByTopic[messages[i].topic[j]] += 1;
            } else {
                totalRatingByTopic[messages[i].topic[j]] = parseInt(messages[i].rating);
                totalMessagesByTopic[messages[i].topic[j]] = 1;
            }
        }
    }
    for (let i = 0; i < Object.keys(totalRatingByTopic).length; i++) {
        totalRatingByTopic[Object.keys(totalRatingByTopic)[i]] = totalRatingByTopic[Object.keys(totalRatingByTopic)[i]] / totalMessagesByTopic[Object.keys(totalRatingByTopic)[i]];
    }

    let keys = Array.from(Object.keys(totalRatingByTopic));
    let values = Array.from(Object.values(totalRatingByTopic));
    let topicAndAvgRatingJSON = [{}];
    for (let i = 0; i < keys.length; i++) {
        topicAndAvgRatingJSON[i] = { topic: keys[i], avgRating: Math.round(values[i] * 100) / 100 };
    }

    return topicAndAvgRatingJSON;

}
export async function queryUsersLabels() {

}

/*
* This function gets the average sentiment score
*/
export async function getAvgScore(messages) {
    let totalScore = 0;
    for (let i = 0; i < messages.length; i++) {
        if (messages[i].rating != null) {
            totalScore += parseInt(messages[i].rating);
        }
    }
    let avgScore = totalScore / messages.length;

    if (isNaN(avgScore)) {
        return "No data";
    }
    else {
        return avgScore.toFixed(2);
    }

}

export async function getTop10Topic(messages) {
    let foundTopics = {};
    let totalTopics = 0;
    for (let i = 0; i < messages.length; i++) {
        if (messages[i].topic == null) {
            continue;
        }
        for (let j = 0; j < messages[i].topic.length; j++) {
            if (messages[i].topic[j] in foundTopics) {
                foundTopics[messages[i].topic[j]] += 1;
            } else {
                foundTopics[messages[i].topic[j]] = 1;
                totalTopics += 1;
            }
        }
    }
    let keys = Array.from(Object.keys(foundTopics));
    let values = Array.from(Object.values(foundTopics));


    let top10keys = [];
    let top10values = [];
    for (let i = 0; i < 10; i++) {
        let max = Math.max(...values);
        let index = values.indexOf(max);
        if (index == -1) {
            break;
        }
        top10keys.push(keys[index]);
        top10values.push(values[index]);
        values.splice(index, 1);
        keys.splice(index, 1);
    }
    return getChartProps(top10values, top10keys)
}



export async function getTotalOfSentiments(messages) {
    let foundSentimentRatings = {};
    let totalSentimentRatings = 0;
    for (let i = 0; i < messages.length; i++) {
        if (messages[i].rating == null) {
            continue;
        }
        if (messages[i].rating in foundSentimentRatings) {
            foundSentimentRatings[messages[i].rating] += 1;
        } else {
            foundSentimentRatings[messages[i].rating] = 1;
            totalSentimentRatings += 1;
        }
    }
    let keys = Array.from(Object.keys(foundSentimentRatings));
    let values = Array.from(Object.values(foundSentimentRatings));
    return getChartProps(values, keys)

}

export async function getTopicCountByDate() {
    const userName = await getCurrentUserID();
    const variables = {
        filter: { customer_id: { eq: userName } },
        hidden: false
    };
    const messages = await API.graphql({
        query: queries.posted_at_by_date,
        variables: variables
    });
    let foundTopics = [];
    let timeStamps = [];

    for (let i = 0; i < messages.data.posted_at_by_date.items.length; i++) {
        foundTopics.push(messages.data.posted_at_by_date.items[i].topic.length)
        timeStamps.push(new Date(messages.data.posted_at_by_date.items[i].timestamp).toDateString())
    }
    return getChartProps(foundTopics, timeStamps)

}
/*
*  This function generates a random rgba color
*/
export function get_rgba() {
    var rbgs = ['rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)']


}


