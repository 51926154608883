import React from 'react';
import './InputMessagePage.css';

function InputMessagePage() {
    return (
        <>
            <div className='container'>
                <h1 className='message_header'>Message Mayor Smith</h1>
                <div className='div_select'>
                    <div className='message_select'>
                        <p><i>Message</i></p>
                    </div>
                    <h2 className="below_header"> More Info {'>'} </h2>
                </div>
                <form>
                    <textarea className="message_form" type="text" placeholder="Type your message..."></textarea>
                </form>
                <button className='SendButton'>Send</button>
            </div>
        </>
    );
}
export default InputMessagePage;