// RequireAuth.js
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import React from 'react';
import Sidebar from "../Components/SidebarNav/Sidebar";
import secureLocalStorage from "react-secure-storage";
import SubscriptionAlertBanner from "../Components/AlertBanners/AlertBanner";

class RequireAuth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            route: null,
            isLoggedIn: false
        };
        const loggedInUser = JSON.parse(secureLocalStorage.getItem("user"));
        if (loggedInUser) {
            this.state = {
                route: null,
                isLoggedIn: true
            };
        }
    }

    async componentDidMount() {
        const loggedInUser = JSON.parse(secureLocalStorage.getItem("user"));
        if (loggedInUser) {
            this.setState({ isLoggedIn: true });
        }
        else {
            this.setState({ isLoggedIn: false });
        }
    }

    render() {
        const { children } = this.props;
        const { route } = this.state;

        if (this.state.isLoggedIn) {
            return (
                <>
                    <SubscriptionAlertBanner />
                    <Sidebar />
                    {children}
                </>
            );
        }
        else {
            return <Navigate to="/SignIn" state={{ from: route }} replace />;
        }
    }
}
export default RequireAuth;