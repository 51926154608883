import React from "react";
import "./Label.css";
import { ReactComponent as LabelArt } from "../../../Images/label_grey_24dp.svg"


class Label extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.state = { isSelected: this.props.isSelected };
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }

    componentDidUpdate(prevProps) {
        if (this.props.isSelected !== prevProps.isSelected) {
            this.setState({ isSelected: this.props.isSelected });
        }
    }

    /*
    * This function is called when a user clicks on a label.
    * It checks if the label is null, and if it is not, it checks if the user clicked on the label.
    * If the user clicked on the label, it calls the updateSelected function from the parent component.
    */
    handleClick(e) {
        if (this.props.label === null) {
            return;
        }
        else if (this.props.label.title === undefined || this.props.label.color === undefined) {
            return null

        }
        if (this.node.contains(e.target)) {
            if (JSON.stringify(this.state.isSelected) === JSON.stringify(this.props.label)) {
                this.setState({ isSelected: "" });
                if (this.props.updateSelected != undefined) {
                    this.props.updateSelected("");
                }
            }
            else {
                if (this.props.updateSelected != undefined) {
                    this.props.updateSelected(this.props.label);
                }
            }

        }
    }

    render() {

        if (this.props.label === null || this.props.label === undefined) {
            return (
                null
            )
        }
        else if (this.props.label.title === undefined || this.props.label.color === undefined) {
            return (
                null
            )
        }
        else if (this.props.isSelected === this.props.label) {
            return (
                <div className="label-container-selected" ref={node => this.node = node}>
                    <LabelArt fill={this.props.label.color} className="label-icon" />
                    <div className="label-text-container">
                        <p className="label-text">{this.props.label.title}</p>
                    </div>
                </div >
            )
        }
        else {
            return (
                <div className="label-container" ref={node => this.node = node}>
                    <LabelArt fill={this.props.label.color} className="label-icon" />
                    <div className="label-text-container">
                        <p className="label-text">{this.props.label.title}</p>
                    </div>
                </div >
            )
        }
    }
}

export default Label;