import React from "react";
import "./MessagePreview.css";
import { getISO8601DateTime, getISO8601Time, formatTimeDateMessagePreview } from "../Helpers/MessageListHelpers";
import Label from "./Label";

class MessagesPreview extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = { isSelected: this.props.isSelected, isHidden: this.props.isHidden };
    }

    componentDidMount() {
        if (this.props.isHidden !== true) {
            document.addEventListener('click', this.handleClick);
        }
    }

    componentWillUnmount() {
        if (this.props.isHidden !== true) {
            document.removeEventListener('click', this.handleClick);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isSelected !== prevProps.isSelected || this.props.isHidden !== prevProps.isHidden) {
            this.setState({ isSelected: this.props.isSelected });
            this.setState({ isHidden: this.props.isHidden });
        }
    }

    /* 
    * This function is called when a user clicks on a message preview.
    * It checks if the message preview is hidden, and if it is not, it checks if the user clicked on the message preview.
    * If the user clicked on the message preview, it calls the updateSelected function from the parent component.
    */
    handleClick(e) {
        if (this.props.isHidden !== true && this.node.contains(e.target)) {
            this.props.updateSelected(this.props.messageID);

        }
    }

    render() {
        if (this.state.isHidden === true) {
            return (
                <div className="hidden"></div>
            );
        }
        else if (this.props.isSelected === true) {
            return (
                <div className="message-preview-container" ref={node => this.node = node}>
                    <div className="message-preview-flex-container-selected">
                        <div className="message-preview-left">

                            <div className="message-preview-body-container">
                                <div className="message-preview-body">{this.props.body}</div>
                            </div>
                            <div className="message-preview-label-container">
                                <Label label={this.props.label} />
                            </div>
                        </div>
                        <div className="message-preview-right">

                            <div className="message-preview-time-container">
                                <div className="message-preview-time">{formatTimeDateMessagePreview(this.props.timestamp)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )

        } else {
            return (
                <div className="message-preview-container " ref={node => this.node = node}>
                    <div className="message-preview-flex-container">
                        <div className="message-preview-left">

                            <div className="message-preview-body-container">
                                <div className="message-preview-body">{this.props.body}</div>
                            </div>
                            <div className="message-preview-label-container">
                                <Label label={this.props.label} />
                            </div>
                        </div>
                        <div className="message-preview-right">
                            <div className="message-preview-time-container">
                                <div className="message-preview-time">{formatTimeDateMessagePreview(this.props.timestamp)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default MessagesPreview;