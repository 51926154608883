
import React, { useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Colors
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import "./BarChart.css";
import { queryAllTopicCounts } from "../Helpers/GetData";
import { DropdownList } from "react-widgets";
import "react-widgets/styles.css";
import { Loader } from '@aws-amplify/ui-react';

function BarChart({ xAxis, yAxis, topics, command }) {
    const [Data, setData] = useState({
        labels: [],
        datasets: [
            {
                data: [],

            },
        ]

    });
    const [yValue, setYValue] = useState("Topics");
    const [xValue, setXValue] = useState("Count");
    const [title, setTitle] = useState("Trending Topics");
    const [isLoaded, setIsLoaded] = useState(false);
    ChartJS.defaults.font.family = "AvenirLTProRoman"
    ChartJS.register(
        Colors,
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,

    );


    const options = {

        plugins: {
            legend: {
                display: false
            }
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
        },
        scales: {
            y:
            {
                min: 0,
                ticks: {
                    stepSize: 1,
                },
            },
            x:
            {

            },
        }
    }

    if (command === "queryAllTopicCounts" && !isLoaded) {
        queryAllTopicCounts().then((data) => {
            setData(data)
            setTitle("Topic Counts")
            setIsLoaded(true)
        }).catch(err => console.log(err));;
    }

    if (isLoaded) {
        return (
            <>
                <div className="insights-card-top-container">
                    <div className="insights-card-title">
                        <h2>{title}</h2>
                    </div>

                </div>
                <Bar options={options} data={Data} />
            </>
        )
    }
    else {
        <>
            <Loader size="large" />
        </>
    }
}

export default BarChart;