/*
    * This file contains the functions that are used to filter and sort the messages
    *
    * JSON format:
        [{
            messageID: "1",
            body: "Example paragraph",
            timestamp: "1994-11-05T08:30:30-05:00",
            email: "example@gmail.com",
            topic: "Topic 1",
            rating: 3,
            city: "Philadelphia",
            state: "Pennsylvania",
            country: "United States",
            label: ["Label 1", "Label 2"],
            notification: true,
            isSelected: true,
            isHidden: false
        }]
    *
    * */

/*
* This function returns the labels of the messages
* @param messages is the array of messages
* */
export function getLabels(messages) {
    var labels = [];
    let found = false;

    for (let i = 0; i < messages.length; i++) {
        if (messages[i].label === null || messages[i].label === undefined) {
            continue;
        }
        else {
            for (let k = 0; k < labels.length; k++) {
                if (JSON.stringify(messages[i].label) === JSON.stringify(labels[k])) {

                    found = true;
                }
            }

            if (found === false && messages[i].label.title !== undefined && messages[i].label.color !== undefined) {
                labels.push(messages[i].label);
            }

            found = false;
        }
    }
    return labels;
}

/*
* takes string and formats it to be a valid date
* @param isostr is the date and time of the message
* */
export function getISO8601Time(isostr) {
    console.log(isostr + " UTC")
    //Get local time from isostr
    var parts = isostr.match(/\d+/g);
    let date = new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
    let localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return localDate.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
}

/*
* takes string and formats it to be a valid time
* @param isostr is the date and time of the message
* */
export function getISO8601DateTime(isostr) {
    //Get local date from isostr
    var parts = isostr.match(/\d+/g);
    let date = new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
    let localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return localDate.toLocaleDateString();
}

/*
* takes string and formats it to be a valid date and time
* @param isostr is the date and time of the message
* */
export function getISO8601TimeAndDate(isostr) {
    //Get local date and time from isostr
    var parts = isostr.match(/\d+/g);
    let date = new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
    let localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return localDate.toLocaleDateString() + " " + localDate.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
}

/*
* this function formates the date and time of the message into a string depending on if it was sent yesterday or not 
* @param isostr is the date and time of the message
* @returns the formatted date and time
* */
export function formatTimeDateMessagePreview(isostr) {
    let messageDate = new Date(isostr);
    if (isToday(messageDate)) {
        return getISO8601Time(isostr);
    }
    else {
        return getISO8601DateTime(isostr);
    }
}

/*
* this function returns the messages that are not hidden
* @param messages is the list of messages
* @returns the true if the message was from yesterday
* */
export function isToday(date) {
    if (!(date instanceof Date)) {
        throw new Error('Invalid argument: you must provide a "date" instance')
    }

    const today = new Date()
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
}

/*
* this function returns the selected message
* @param messages is the list of messages
* @param messageID is the messageID of the message to return
* */
export function returnSelected(messages, messageID) {
    for (let i = 0; i < messages.length; i++) {
        if (messages[i].messageID === messageID) {
            return messages[i];
        }
    }
}

/*
* this function sorts the messages by date
* @param messages is the list of messages
* @param ascending is a boolean that determines if the messages are sorted ascending or descending
* */
export function sortByDate(messages, ascending) {
    var data = messages['results'];
    if (ascending === true) {
        data.sort(function (a, b) {
            return new Date(b['created']) - new Date(a['created']);
        });
    }
}

/*
    * This function filters the messages by the search term in the body
    * @param messages is the list of messages
    * @param search is the search term to filter by
    * */
export function searchByBody(messages, search) {
    var data = messages;

    if (search !== "") {
        var filtered = messages.filter(function (item) {
            return item['body'].toLowerCase().includes(search.toLowerCase());
        });

        for (let i = 0; i < data.length; i++) {
            if (!filtered.includes(data[i])) {
                data[i].isHidden = true;
            }
            else {
                data[i].isHidden = false;
            }
        }


    }
    else {
        for (let i = 0; i < data.length; i++) {
            data[i].isHidden = false;
        }

        return data
    }

    return data

}


/*
    * This function filters the messages by the label
* @param messages is the list of messages
* @param label is the label to filter by
    * */
export function filterByLabel(messages, label) {
    var data = messages;
    if (label !== "") {
        for (let i = 0; i < data.length; i++) {
            if (JSON.stringify(data[i].label) === JSON.stringify(label)) {
                data[i].isHidden = false;
            }
            else {
                data[i].isHidden = true;
            }

        }


        return data;
    }

    return data;
}

/*
    * This function filters the messages by the search term in the email
    * @param messages is the list of messages
    * @param messageID is the messageID of the message to mutate
    * @param key is the key of the message attribute to be updated
    * @param value is the value of the message attribute to assign
    */
export function updateMessageLabel(messages, messageID, title, color) {
    for (let i = 0; i < messages.length; i++) {
        if (messages[i].messageID === messageID) {
            messages[i].label = { color: color, title: title, };
        }
    }
    return messages;
}