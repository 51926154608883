/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      message_id
      customer_id
      first_name
      last_name
      message_string
      message_hash
      email
      state
      city
      country
      topic
      rating
      timestamp
      hidden
      lat
      long
      createdAt
      updatedAt
      label
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      message_id
      customer_id
      first_name
      last_name
      message_string
      message_hash
      email
      state
      city
      country
      topic
      rating
      timestamp
      hidden
      lat
      long
      createdAt
      updatedAt
      label
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      message_id
      customer_id
      first_name
      last_name
      message_string
      message_hash
      email
      state
      city
      country
      topic
      rating
      timestamp
      hidden
      lat
      long
      createdAt
      updatedAt
      label
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      customer_id
      first_name
      last_name
      email
      address
      birthdate
      industry
      company_name
      created_at
      updated_at
      secret
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      customer_id
      first_name
      last_name
      email
      address
      birthdate
      industry
      company_name
      created_at
      updated_at
      secret
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      customer_id
      first_name
      last_name
      email
      address
      birthdate
      industry
      company_name
      created_at
      updated_at
      secret
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerSubscription = /* GraphQL */ `
  mutation CreateCustomerSubscription(
    $input: CreateCustomerSubscriptionInput!
    $condition: ModelCustomerSubscriptionConditionInput
  ) {
    createCustomerSubscription(input: $input, condition: $condition) {
      customer_id
      status
      subscription_tier
      stripeID
      totalTokens
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerSubscription = /* GraphQL */ `
  mutation UpdateCustomerSubscription(
    $input: UpdateCustomerSubscriptionInput!
    $condition: ModelCustomerSubscriptionConditionInput
  ) {
    updateCustomerSubscription(input: $input, condition: $condition) {
      customer_id
      status
      subscription_tier
      stripeID
      totalTokens
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerSubscription = /* GraphQL */ `
  mutation DeleteCustomerSubscription(
    $input: DeleteCustomerSubscriptionInput!
    $condition: ModelCustomerSubscriptionConditionInput
  ) {
    deleteCustomerSubscription(input: $input, condition: $condition) {
      customer_id
      status
      subscription_tier
      stripeID
      totalTokens
      createdAt
      updatedAt
    }
  }
`;
export const createContactFormInfo = /* GraphQL */ `
  mutation CreateContactFormInfo(
    $input: CreateContactFormInfoInput!
    $condition: ModelContactFormInfoConditionInput
  ) {
    createContactFormInfo(input: $input, condition: $condition) {
      customer_id
      form_id
      title
      fields
      createdAt
      updatedAt
    }
  }
`;
export const updateContactFormInfo = /* GraphQL */ `
  mutation UpdateContactFormInfo(
    $input: UpdateContactFormInfoInput!
    $condition: ModelContactFormInfoConditionInput
  ) {
    updateContactFormInfo(input: $input, condition: $condition) {
      customer_id
      form_id
      title
      fields
      createdAt
      updatedAt
    }
  }
`;
export const deleteContactFormInfo = /* GraphQL */ `
  mutation DeleteContactFormInfo(
    $input: DeleteContactFormInfoInput!
    $condition: ModelContactFormInfoConditionInput
  ) {
    deleteContactFormInfo(input: $input, condition: $condition) {
      customer_id
      form_id
      title
      fields
      createdAt
      updatedAt
    }
  }
`;
export const createSubscriptionInfo = /* GraphQL */ `
  mutation CreateSubscriptionInfo(
    $input: CreateSubscriptionInfoInput!
    $condition: ModelSubscriptionInfoConditionInput
  ) {
    createSubscriptionInfo(input: $input, condition: $condition) {
      subscription_tier
      cost
      createdAt
      updatedAt
    }
  }
`;
export const updateSubscriptionInfo = /* GraphQL */ `
  mutation UpdateSubscriptionInfo(
    $input: UpdateSubscriptionInfoInput!
    $condition: ModelSubscriptionInfoConditionInput
  ) {
    updateSubscriptionInfo(input: $input, condition: $condition) {
      subscription_tier
      cost
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubscriptionInfo = /* GraphQL */ `
  mutation DeleteSubscriptionInfo(
    $input: DeleteSubscriptionInfoInput!
    $condition: ModelSubscriptionInfoConditionInput
  ) {
    deleteSubscriptionInfo(input: $input, condition: $condition) {
      subscription_tier
      cost
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerEmail = /* GraphQL */ `
  mutation CreateCustomerEmail(
    $input: CreateCustomerEmailInput!
    $condition: ModelCustomerEmailConditionInput
  ) {
    createCustomerEmail(input: $input, condition: $condition) {
      customer_id
      email
      imap_address
      folder
      search
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerEmail = /* GraphQL */ `
  mutation UpdateCustomerEmail(
    $input: UpdateCustomerEmailInput!
    $condition: ModelCustomerEmailConditionInput
  ) {
    updateCustomerEmail(input: $input, condition: $condition) {
      customer_id
      email
      imap_address
      folder
      search
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerEmail = /* GraphQL */ `
  mutation DeleteCustomerEmail(
    $input: DeleteCustomerEmailInput!
    $condition: ModelCustomerEmailConditionInput
  ) {
    deleteCustomerEmail(input: $input, condition: $condition) {
      customer_id
      email
      imap_address
      folder
      search
      createdAt
      updatedAt
    }
  }
`;
