import { useState, useEffect } from "react";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { getCustomerSecret } from "../../Utils/ListCustomers";

import './ContactFormGenerator.css';

function ContactForm() {
    const { userId } = useParams();

    // Private consts
    const [refyneCustomerSecret, setrefyneCustomerSecret] = useState("");
    const [isLoading, setLoading] = useState(true);
    const [isLoaded, setisLoaded] = useState(false);
    const [formSubmitted, setformSubmitted] = useState(false);

    // Fields from contact form
    const [messageInputText, setmessageInputText] = useState("");
    const [messageInputFirstName, setmessageInputFirstName] = useState("");
    const [messageInputLastName, setmessageInputLastName] = useState("");
    const [messageInputEmail, setmessageInputEmail] = useState("");



    useEffect(() => {

        async function getRefyneCustomerSecret() {
            const result = await getCustomerSecret(userId);
            setrefyneCustomerSecret(result);
            setLoading(false);
            setisLoaded(true);

        }

        if (isLoaded === false) {
            getRefyneCustomerSecret()
        }

    }, []);

    function sendRequest() {

        let data = JSON.stringify({
            "message": messageInputText,
            "first_name": messageInputFirstName,
            "last_name": messageInputLastName,
            "email": messageInputEmail
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://2qytzny5d3.execute-api.us-east-1.amazonaws.com/prod',
            headers: {
                'customerid': userId,
                'customersecret': refyneCustomerSecret,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            });

    }

    function handleOnClick(e) {
        e.preventDefault();

        sendRequest();

        setformSubmitted(true);
    }

    if (!formSubmitted) {
        return (

            <>
                <div className="contact-form-generator-page">
                    <div className="contact-form-generator-page-title">
                        <h1>Contact Form</h1>
                    </div>
                    <div className="contact-form-generator-page-container">

                        <input className="form-input" placeholder="Your first name" onChange={(e) => setmessageInputFirstName(e.target.value)} />
                        <input className="form-input" placeholder="Your last name" onChange={(e) => setmessageInputLastName(e.target.value)} />
                        <input className="form-input" placeholder="Your email" onChange={(e) => setmessageInputEmail(e.target.value)} />
                        <br></br>
                        <input className="form-input" placeholder="Enter Message" onChange={(e) => setmessageInputText(e.target.value)} />
                        <br></br>
                        <button className="send-message-button" type="button" onClick={handleOnClick}>Send Message</button>
                    </div>
                </div>
            </>
        )
    }
    else {
        return (

            <>
                <div className="contact-form-generator-page">
                    <div className="contact-form-generator-page-title">
                        <h1>Contact Form</h1>
                    </div>
                    <div className="contact-form-generator-page-container">

                        <p>
                            Thank you. Your message has been sent!
                        </p>
                    </div>
                </div>
            </>
        )
    }
}

export default ContactForm;