import React from "react";
import secureLocalStorage from "react-secure-storage";
import { getStripePortalSessionUrl } from './Helpers/getStripePortalSessionUrl';
import './Settings.css';

function Settings() {
    function handleOnClick(e) {
        e.preventDefault();

        const loggedInUser = JSON.parse(secureLocalStorage.getItem("user"));

        getStripePortalSessionUrl(loggedInUser.username, loggedInUser.customersecret)
            .then(url => {
                window.open(url);
            }).catch(err => {
                console.log('Error retrieving Stripe portal session URL');
                console.log(err);
            })
    }

    return (
        <>
            <div className="settings-page">
                <div className="settings-page-title">
                    <h1>Settings</h1>
                </div>
                <div className="settings-page-container">
                    <button className="manage-subscription-button" type="button" onClick={handleOnClick}>Manage Subscription</button>
                </div>
            </div>
        </>
    )
}

export default Settings;