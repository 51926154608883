import React, { useState } from "react";
import "./Sidebar.css";
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import secureLocalStorage from "react-secure-storage";


function Sidebar() {


    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);

    const insightsSVG = <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="24.5" viewBox="0 0 40.332 28.729"><g id="insights_black_24dp" transform="translate(0 4.729)"><g id="Group_8" data-name="Group 8"><rect id="Rectangle_76" data-name="Rectangle 76" width="24" height="24" fill="none" /></g><g id="Group_10" data-name="Group 10" transform="translate(0 -12.729)"><g id="Group_9" data-name="Group 9" transform="translate(1 3)"><path id="Path_24" data-name="Path 24" d="M36.756,8c-2.592,0-4.04,2.967-3.45,5.172l-6.347,7.336a3.176,3.176,0,0,0-1.859,0l-4.559-5.255c.608-2.2-.84-5.193-3.45-5.193-2.592,0-4.058,2.967-3.45,5.193L5.487,24.63C3.574,23.95,1,25.619,1,28.607a3.889,3.889,0,0,0,3.576,4.121c2.592,0,4.04-2.967,3.45-5.172l8.135-9.4a3.176,3.176,0,0,0,1.859,0l4.559,5.255c-.608,2.2.84,5.193,3.45,5.193,2.592,0,4.058-2.967,3.45-5.193L35.845,16.1c1.913.68,4.487-.989,4.487-3.977A3.889,3.889,0,0,0,36.756,8Z" transform="translate(-1 -3)" fill="#FFFFFF" /></g></g></g> </svg>

    function HeaderView() {
        const location = useLocation();
        return <span>Path : {location.pathname}</span>
    }

    HeaderView()
    return (
        <div className="sidebar">
            <SideNav
                onSelect={(selected) => {
                    if (selected === "signOut") {
                        Auth.signOut();
                        secureLocalStorage.clear();
                        console.log("signed out")
                        navigate('/');
                    }
                    else {
                        navigate('/' + selected);
                        setIsExpanded(false);
                    }
                }}
                style={{
                    backgroundColor: "#1a1a1a", color: "#000000"
                }}
                expanded={isExpanded}
                onToggle={(expanded) => {
                    setIsExpanded(expanded);
                }}
            >
                <SideNav.Toggle />
                <SideNav.Nav defaultSelected="home">
                    <NavItem eventKey="Home">
                        <NavIcon style={{ marginTop: "3px" }}>
                            <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em', color: "#FFFFFF" }} />
                        </NavIcon>
                        <NavText style={{ color: "#FFFFFF" }}>
                            Home
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="messages">
                        <NavIcon style={{ marginTop: "3px" }}>
                            <i className="fa fa-fw fa-envelope" style={{ fontSize: '1.75em', color: "#FFFFFF" }} />
                        </NavIcon>
                        <NavText style={{ color: "#FFFFFF" }}>
                            Reviews
                        </NavText>

                    </NavItem>
                    <NavItem eventKey="insights">
                        <NavIcon style={{ marginTop: "8px" }}>
                            {insightsSVG}
                        </NavIcon>
                        <NavText style={{ color: "#FFFFFF" }}>
                            Insights
                        </NavText>
                    </NavItem>
                    {/*<NavItem eventKey="VisualMap">
                        <NavIcon style={{ marginTop: "8px" }}>
                            <i className="fa fa-fw fa-map" style={{ fontSize: '1.75em', color: "#FFFFFF" }} />
                        </NavIcon>
                        <NavText style={{ color: "#FFFFFF" }}>
                            Visual Map
                        </NavText>
                        </NavItem>*/}
                    <NavItem eventKey="import">
                        <NavIcon style={{ marginTop: "4px" }}>
                            <i className="fa fa-cloud-upload" style={{ fontSize: '1.75em', color: "#FFFFFF" }} />
                        </NavIcon>
                        <NavText style={{ color: "#FFFFFF" }}>
                            Import
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="settings">
                        <NavIcon style={{ marginTop: "4px" }}>
                            <i className="fa fa-fw cogs fa-cogs" style={{ fontSize: '1.75em', color: "#FFFFFF" }} />
                        </NavIcon>
                        <NavText style={{ color: "#FFFFFF" }}>
                            Settings
                        </NavText>

                    </NavItem>
                    <NavItem eventKey="signOut" style={{ position: "absolute", bottom: "0px", width: "100%" }}>
                        <NavIcon style={{ marginTop: "4px" }}>
                            <i className="fa fa-sign-out" style={{ fontSize: '1.75em', color: "#FFFFFF" }}></i>
                        </NavIcon>
                        <NavText style={{ color: "#FFFFFF" }}>
                            Logout
                        </NavText>
                    </NavItem>
                </SideNav.Nav>
            </SideNav>
        </div>
    );
}

export default Sidebar;