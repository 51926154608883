import { useState } from 'react';
import classes from './SignIn.module.css';
import { useNavigate } from "react-router-dom";
import '@aws-amplify/ui-react/styles.css';
import { Auth, API } from "aws-amplify";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import SignUpArt from "../../Images/SignUpArt.svg";
import { validateEmail, validatePassword } from "../../Utils/Validator.js";
import secureLocalStorage from "react-secure-storage";
import LogoIcon from "../../Images/Logo-NoBG.png"
import Navbar from '../LandingPage/Navbar';

function SignIn({ signOut, user }) {
  const [emailInput, setEmailInput] = useState(""); // Email
  const [passwordInput, setPasswordInput] = useState(""); // Password
  const [errorMessage, setErrorMessage] = useState(""); // Error message to display to use
  const navigate = useNavigate(); // Navigate function
  var userInfo;

  async function confirmSignIn() {
    const confirmSignIn_Promise = await new Promise(async (resolve, reject) => {
      try {
        await Auth.signIn(emailInput, passwordInput).then((user) => {
          if (user) {
            userInfo = user;
            resolve(user.username);
          }
          else {
            reject("No user is logged in");
          }
        });
      } catch (err) {
        setErrorMessage(err.message);
      }
    });
    if (confirmSignIn_Promise) {
      const confrimCookie_Promise = await new Promise(async (resolve, reject) => {
        const variables = {
          customer_id: confirmSignIn_Promise
        };


        await API.graphql({
          query: queries.getCustomer,
          variables: variables
        }).then((customerJSON) => {
          userInfo.customersecret = customerJSON.data.getCustomer.secret
          secureLocalStorage.setItem('user', JSON.stringify(userInfo));
          navigate("/Home")
          resolve(customerJSON);

        }).catch(err => setErrorMessage(err));

      });
    }
  }

  return (
    <>

      <div className={classes.signUpPage}>
        <Navbar />
        <div className={classes.homePageSafeAreaSignUp}>
          <div className={classes.flexContainerSignIn}>
            <div className={classes.signUpForm}>

              <div className={classes.signInLogoIconContainer}>
                <img className={classes.signInLogoIcon} src={LogoIcon}></img>
              </div>
              <div className={classes.titleMessageContainerSignIn}>
                <p className={classes.titleMessageSignIn}>Sign In</p>
              </div>
              <div className={classes.formContainer}>
                <form>
                  {/* Email input */}
                  <label className={classes.inputLabel}>What is your email?</label>
                  <br></br>
                  <input type="email" value={emailInput} className={classes.formInputSignUp} aria-describedby="emailHelp" placeholder="Enter email" onChange={(e) => setEmailInput(e.target.value)} />
                  <br></br>
                  {emailInput.length > 0 && !validateEmail(emailInput) && (
                    <label style={{ color: "rgb(255, 0, 0)" }}>
                      Please enter a valid <em>email</em>
                    </label>
                  )}

                  <br></br>
                  {/* Password input */}
                  <label>Password</label>
                  <br></br>
                  <input type="password" value={passwordInput} className={classes.formInputSignUp} placeholder="Password" onChange={(e) => setPasswordInput(e.target.value)} />
                  <br></br>
                  {passwordInput.length > 0 && !validatePassword(passwordInput) && (
                    <>
                      <label style={{ color: "rgb(255, 0, 0)" }}>
                        Please enter a valid <em>password</em>
                      </label>
                    </>
                  )}
                </form>
              </div>
              <div className={classes.signUpButtonContainer}>
                <button className={classes.nextButton} onClick={() => {
                  confirmSignIn();
                }}>Sign In</button>
              </div>
              <br></br>
              <label onClick={() => {
                navigate("/SignUp")
              }}><i><u>Don't have an account?</u></i></label>
              {errorMessage.length > 0 && (
                <>
                  <br></br>
                  <label style={{ color: "rgb(255, 0, 0)" }}>
                    {errorMessage}
                  </label>
                  <br></br>
                </>
              )}

            </div>
            {/*
          <div className={classes.art}>
            <img src={SignUpArt} alt="SignUp" />
          </div>
            */}
          </div>
        </div >
      </div>
    </>
  );
}

export default SignIn;