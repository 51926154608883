import React from 'react';
import '../LandingPage.css';
import ContactForm from '../ContactForm';
import Navbar from '../Navbar';

function Home() {
    return (
        <>
            <Navbar />
            <ContactForm />
        </>
    )
}
export default Home;