/* eslint-disable */
import {
    MapView,
    View,
    Flex,
    Text,
    SelectField,
    Card
} from '@aws-amplify/ui-react';
import "./VisualMap.css";
import { API } from "aws-amplify";
import { messages_by_date_and_customerid } from "../../graphql/queries";
import { useState, useEffect, useRef, createRef, useCallback } from "react";
import { MarkerWithPopup } from './MapWithMarkerPopup';
import { getCurrentUserID, getDateDiff } from "./Helpers/GetData.js";
import { Button, ScrollView } from '@aws-amplify/ui-react';
import { useMap } from 'react-map-gl';
import Sidebar from "../../Components/SidebarNav/Sidebar";
import { QueryClient, QueryClientProvider, useInfiniteQuery, fetchNextPage } from "react-query";

// React-Query Query Client
const queryClient = new QueryClient()

export default function VisualMap() {
    return (
        <>
            <div className="visual-map-page-safe-area">


                <Sidebar>
                </Sidebar>
                <QueryClientProvider client={queryClient}>
                    <VisualMapCore />
                </QueryClientProvider>
            </div>
        </>

    )
}


function VisualMapCore() {

    // Messages Data Length
    const [messagesDataLength, setMessagesDataLength] = useState(null);

    // Amplify Refyne Customer ID - for authenticating queries
    const [refyneCustomerId, setrefyneCustomerId] = useState(null);

    // Filter button states
    const [timeFilter, setTimeFilter] = useState(``);
    const [sentimentFilter, setSentimentFilter] = useState(``);
    const [numItemsFilter, setNumItemsFilter] = useState('5');

    const { isLoading, isError, error, data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } = useFetchData(refyneCustomerId, timeFilter);

    useEffect(() => {

        async function getRefyneCustomerId() {
            const result = await getCurrentUserID();
            setrefyneCustomerId(result);
            return result;
        }

        getRefyneCustomerId().then(() => {
            <useFetchData />
        });
    }, []);




    const [messagesData, setMessagesData] = useState(null);

    // Map Latitude and Longitudinal values
    const [mapZoom, setMapZoom] = useState(3.5);
    const [mapLatitude, setMapLatitude] = useState(40);
    const [mapLongitude, setMapLongitude] = useState(-75);


    const mapRef = useRef();

    const flyToMordor = useCallback((lat, lng) => {
        mapRef.current.flyTo({ center: [lng, lat], zoom: 10 });
    }, []);


    // Function that handles change of clicking on the "Center Map" button
    function handleCenterMapClick() {
        const lat_lng = getAvgLatAndLng();

        // Update the map center coordinates
        setMapLatitude(lat_lng[0]);
        setMapLongitude(lat_lng[1]);
        setMapZoom(2);

        flyToMordor(lat_lng[0], lat_lng[1]);
    }

    // Function that returns the average of the current lat and long coordinates as an array
    function getAvgLatAndLng() {

        let lat_total = 0;
        let lng_total = 0;

        let item_count = 0;

        data.pages.map((page) => {
            if (page) {
                page.items.forEach(function (msg) {
                    if (msg["lat"] && msg["long"]) {
                        // Invert values - lat is long and long is lat.
                        lat_total += parseInt(msg["long"]);
                        lng_total += parseInt(msg["lat"]);
                        item_count += 1;
                    }
                });
            }
        }
        )

        const messagesLen = item_count;
        return [lat_total / messagesLen, lng_total / messagesLen]
    }

    // Function that returns the appropriate location string depending on what is passed
    function returnLocationString(city, state, country) {

        if (city == null && state == null && country == null) {
            return "No location data"
        }
        else {
            return city + ", " + state + ", " + country
        }
    }



    function useFetchData(refyneCustomerId, timeFilter) {
        return useInfiniteQuery(
            ["infinitemsgs", timeFilter, sentimentFilter, numItemsFilter, refyneCustomerId],
            async (context) => fetchData(refyneCustomerId, context),
            {
                getNextPageParam: (lastPage, pages) => lastPage?.nextToken
            }
        );
    }

    // Function that fetches data
    const fetchData = async (refyneCustomerId, tokenParam) => {

        // Define the base query object

        let baseQueryObject = {
            query: messages_by_date_and_customerid,
            variables: {
                customer_id: refyneCustomerId,
                sortDirection: 'DESC',
                filter: {
                    hidden: {
                        eq: "false"
                    }

                }
            }
        }

        // Ensure refyneCustomerId has been set
        if (refyneCustomerId) {

            // Get current date
            const today = new Date();

            // ISO 8601 Date String of One day ago
            const previousDay = new Date(today.getTime());
            previousDay.setDate(today.getDate() - 1);
            const oneDayAgoIsoString = previousDay.toISOString();

            // ISO 8601 Date String of One week ago
            const previousWeek = new Date(today.getTime());
            previousWeek.setDate(today.getDate() - 7);
            const oneWeekAgoIsoString = previousWeek.toISOString();

            // ISO 8601 Date String of One month ago
            const previousMonth = new Date(today.getTime());
            previousMonth.setDate(today.getDate() - 30);
            const oneMonthAgoIsoString = previousMonth.toISOString();

            /*
                Set params
            */

            // Set sentiment params
            if (sentimentFilter === "positive") {
                baseQueryObject["variables"]["filter"]["rating"] = { gt: "2" };
            }
            else if (sentimentFilter === "negative") {
                baseQueryObject["variables"]["filter"]["rating"] = { lt: "3" };
            }

            // Set filter params
            if (numItemsFilter === "5") {
                baseQueryObject["variables"]["limit"] = 5;
            }
            else if (numItemsFilter === "10") {
                baseQueryObject["variables"]["limit"] = 10;
            }
            else if (numItemsFilter === "25") {
                baseQueryObject["variables"]["limit"] = 25;
            }
            else if (numItemsFilter === "50") {
                baseQueryObject["variables"]["limit"] = 50;
            }
            else if (numItemsFilter === "100") {
                baseQueryObject["variables"]["limit"] = 100;
            }

            // Set token params
            if (tokenParam.pageParam) {
                baseQueryObject["variables"]["nextToken"] = tokenParam.pageParam;
            }

            // Set time params
            if (timeFilter === "past_day") {
                baseQueryObject["variables"]["timestamp"] = { gt: oneDayAgoIsoString };
            }

            else if (timeFilter === "past_week") {
                baseQueryObject["variables"]["timestamp"] = { gt: oneWeekAgoIsoString };
            }
            else if (timeFilter === "past_month") {
                baseQueryObject["variables"]["timestamp"] = { gt: oneMonthAgoIsoString };
            }
            else {
                // change nothing from the baseQueryObject
            }

            // Make the GraphQL API request
            var apiData = await API.graphql(baseQueryObject);
            const messagesFromAPI = apiData.data.messages_by_date_and_customerid;
            setMessagesData(messagesFromAPI);

            return messagesFromAPI;
        }
        else {
            // Refyne customer ID has not been loaded yet - do nothing
        }
    }

    // if Token = 1 (initial value), do not pass a token parameter
    if (isLoading) {
        return <h2>Loading...</h2>
    }

    if (isError) {
        return <h2>{error.message}</h2>
    }


    return (
        <>
            <View
                alignItems="center">
                <h2>Visual Map</h2>

                <Text>Interact with your messages using the interactive map. Bulk messages are grouped together.</Text>

                <div>
                    <Button
                        onClick={() => handleCenterMapClick()}
                    >
                        Center map
                    </Button>
                </div>

            </View>

            <View>


                <Flex
                    direction="row"
                    justifyContent={'space-between'}
                    padding="50px"
                >

                    <h2> Recent Messages</h2>

                    <Flex>
                        <SelectField
                            label="Filter by Time"
                            placeholder="Any"
                            value={timeFilter}
                            onChange={(e) => setTimeFilter(e.target.value)}
                        >
                            <option value="past_day">Past day</option>
                            <option value="past_week">Past week</option>
                            <option value="past_month">Past month</option>
                        </SelectField>

                        <SelectField
                            label="Filter by Sentiment"
                            placeholder="Any"
                            value={sentimentFilter}
                            onChange={(e) => setSentimentFilter(e.target.value)}
                        >
                            <option value="positive">Positive</option>
                            <option value="negative">Negative</option>
                        </SelectField>
                    </Flex>

                </Flex>





                <Flex direction="row">

                    <ScrollView width="600px" maxHeight="1200px" maxWidth="600px">
                        <Flex
                            padding="50px"
                            direction="column">

                            {data.pages.map((page) => {
                                if (page) {
                                    return page.items.map((message) => (
                                        <MessageLocationCardNew
                                            messageString={message.message_string}
                                            mailAddress={"mailto:" + message.email}
                                            messageLocation={returnLocationString(message.city, message.state, message.country)}
                                            messageTimestamp={getDateDiff(message.timestamp)}
                                            key={message.message_id}
                                        />
                                    ));
                                }
                            })}
                        </Flex>


                        {
                            // Conditionally rendering the Load More button based on whether there is a next page.
                            hasNextPage === true ?
                                <div className='btn-container'>
                                    <Button variant="contained" onClick={fetchNextPage}>Load More</Button>
                                </div>

                                : <p>

                                    All Messages Loaded.
                                </p>
                        }
                        <div>{isFetching && !isFetchingNextPage ? 'Fetching...' : null}</div>

                    </ScrollView>

                    <MapView initialViewState={{ latitude: mapLatitude, longitude: mapLongitude, zoom: mapZoom }}
                        rightPadding="50px"
                        marginRight="50px"
                        ref={mapRef}>

                        {data.pages.map((page) => {
                            if (page) {
                                return page.items.map((message) => (
                                    message.lat &&
                                    message.long &&
                                    // Note that longitude and latitude are INVERTED - bug with react-map-gl
                                    <MarkerWithPopup
                                        latitude={parseFloat(message.long)}
                                        longitude={parseFloat(message.lat)}
                                        key={message.message_id}
                                        props={message}
                                    />
                                ));
                            }
                        })}

                    </MapView>


                </Flex>

                <Flex
                    direction="row"
                    justifyContent={'space-between'}
                    padding="50px"
                >

                    <SelectField
                        label="Default # of Items"
                        value={numItemsFilter}
                        onChange={(e) => setNumItemsFilter(e.target.value)}
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </SelectField>

                </Flex>

            </View>
        </>

    )
}