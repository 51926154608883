import React from "react";
import { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import "./SignUp.css";
import SignUpArt from "../../Images/SignUpArt.svg";
import { validateEmail, validatePassword, validateName, validateBirthday, validateAddress, validateCity, validateState, validateZip } from "../../Utils/Validator.js";
import { Transition } from 'react-transition-group';
import secureLocalStorage from "react-secure-storage";

function SignUp(props) {
    const [pageState, setPageState] = useState(0); // 0 = sign up part 1, 1 = sign up part 2
    const [emailInput, setEmailInput] = useState(""); // Email
    const [passwordInput, setPasswordInput] = useState(""); // Password
    const [passwordConfInput, setPasswordConfInput] = useState(""); // Password confirmation
    const [nameInput, setNameInput] = useState(""); // Full name
    const [birthdayDayInput, setBirthdayDayInput] = useState("");   // Birthday Day
    const [birthdayMonthInput, setBirthdayMonthInput] = useState(""); // Birthday Month
    const [birthdayYearInput, setBirthdayYearInput] = useState(""); // Birthday Year
    const [streetInput, setStreetInput] = useState(""); // Street address
    const [cityInput, setCityInput] = useState(""); // City
    const [zipInput, setZipInput] = useState(""); // Zip code
    const [stateInput, setStateInput] = useState(""); // State of rendered page
    const [verificationCodeInput, setVerificationCodeInput] = useState(""); // Verification code for user to enter
    const [transitionStatePage1, setTransitionStatePage1] = useState(true)
    const [transitionStatePage2, setTransitionStatePage2] = useState(false)
    const [errorMessage, setErrorMessage] = useState(""); // Error message to display to user
    const nodeRef = useRef(null); // Ref for transition
    const nodeRef2 = useRef(null); // Ref for transition
    const navigate = useNavigate(); // Navigate function
    const duration = 200; // Transition duration

    // Default style for transition
    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in`,
        opacity: 0,
    }

    // Transition styles for pages
    const transitionStyles = {
        entering: { opacity: 0 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
    };

    // Determines if user is ready to move onto part two of sign up
    function isReadyForNext() {
        if (passwordInput === passwordConfInput) {
            if (validatePassword(passwordInput)) {
                if (validateEmail(emailInput)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    // Determines if user is ready to sign up
    function isReadyForSignUp() {
        if (validateName(nameInput)) {
            if (validateBirthday(birthdayDayInput, birthdayMonthInput, birthdayYearInput)) {
                if (validateAddress(streetInput)) {
                    if (validateCity(cityInput)) {
                        if (validateState(stateInput)) {
                            if (validateZip(zipInput)) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    /* Sign up function */
    async function signUp() {
        // Add 0s to birthday if needed
        if (birthdayDayInput.length < 2) {
            setBirthdayDayInput("0" + birthdayDayInput);
        }
        if (birthdayMonthInput.length < 2) {
            setBirthdayMonthInput("0" + birthdayMonthInput);
        }

        console.log(birthdayMonthInput + "/" + birthdayDayInput + "/" + birthdayYearInput)

        try {
            await Auth.signUp({
                username: emailInput,
                password: passwordInput,
                attributes: {
                    name: nameInput,
                    family_name: "",
                    birthdate: birthdayMonthInput + "/" + birthdayDayInput + "/" + birthdayYearInput,
                    address: streetInput + ", " + cityInput + ", " + stateInput + " " + zipInput
                }
            });
            /* Once the user successfully signs up, update form state to show the confirm sign up form for MFA */
            setPageState(2);
        } catch (err) { setErrorMessage(err.message) }
    }

    /* Confirm sign up function for MFA */
    async function confirmSignUp() {
        try {
            await Auth.confirmSignUp(emailInput, verificationCodeInput).then(() => {
                secureLocalStorage.clear();
                navigate("/SignIn")
            });
        } catch (err) { setErrorMessage(err.message) }
    }

    return (
        <>

            <div className="signUpPage">
                <div className="homePageSafeArea">
                    <h1 className="titleMessage">Register</h1>

                    {/* If pageState is 0, show sign up part 1 */}
                    {pageState === 0 && (

                        <Transition in={transitionStatePage1} timeout={200} style={{
                            "width": "100%",
                            "height": "100%",
                        }}>

                            {state => (
                                <div ref={nodeRef} style={{
                                    ...defaultStyle,
                                    ...transitionStyles[state],
                                    "width": "100%",
                                    "height": "100%",
                                }}>
                                    <div className="flexContainer">
                                        <div className="signUpForm" >
                                            <form>
                                                {/* Email input */}
                                                <label className="inputLabel">What is your email?</label>
                                                <br></br>
                                                <input type="email" value={emailInput} className="form-input" aria-describedby="emailHelp" placeholder="Enter email" onChange={(e) => setEmailInput(e.target.value)} />
                                                <br></br>
                                                {emailInput.length > 0 && !validateEmail(emailInput) && (
                                                    <label style={{ color: "rgb(255, 0, 0)" }}>
                                                        Please enter a valid <em>email</em>
                                                    </label>
                                                )}

                                                <br></br>
                                                {/* Password input */}
                                                <label>Choose a secure password.</label>
                                                <br></br>
                                                <input type="password" value={passwordInput} className="form-input" placeholder="Password" onChange={(e) => setPasswordInput(e.target.value)} />
                                                <br></br>
                                                <input type="password" value={passwordConfInput} className="form-input" placeholder="Confirm password" onChange={(e) => setPasswordConfInput(e.target.value)} />
                                                <br></br>
                                                <label><i>Password must contain at least 1 number,<br></br>1 letter, and have a minimum of 8 characters.</i></label>

                                                {passwordInput.length > 0 && !validatePassword(passwordInput) && (
                                                    <>
                                                        <br></br>
                                                        <label style={{ color: "rgb(255, 0, 0)" }}>
                                                            Please enter a valid <em>password</em>
                                                        </label>
                                                    </>
                                                )}
                                                {passwordConfInput.length > 0 && passwordInput.length > 0 && passwordInput !== passwordConfInput && (
                                                    <>
                                                        <br></br>
                                                        <label style={{ color: "rgb(255, 0, 0)" }}>
                                                            Passwords do not match
                                                        </label>
                                                    </>
                                                )}
                                            </form>
                                            <br></br>


                                        </div>
                                        <div className="art">
                                            <img src={SignUpArt} alt="SignUp" />
                                        </div>
                                    </div>
                                    <button className="nextButton" disabled={!isReadyForNext()} onClick={() => {
                                        setTransitionStatePage1(false);
                                        setTimeout(() => {
                                            setPageState(1); setTransitionStatePage2(true)
                                        }, 200);

                                    }}>Next</button>
                                    <br></br>
                                    <label onClick={() => {
                                        navigate("/SignIn")
                                    }}><i><u>Already have an account?</u></i></label>
                                </div>
                            )}

                        </Transition>

                    )}
                    {/* If pageState is 1, show sign up part 2 */}
                    {pageState === 1 && (
                        <Transition in={transitionStatePage2} timeout={200} appear={true} >
                            {state2 => (
                                <div ref={nodeRef2} style={{
                                    ...defaultStyle,
                                    ...transitionStyles[state2]
                                }}>
                                    <div className="flexContainer">
                                        <div className="signUpForm">
                                            <form>
                                                <label className="inputLabel">What is your name?</label>
                                                <br></br>
                                                {/* Name Input */}
                                                <input type="text" value={nameInput} className="form-input" aria-describedby="textHelp" placeholder="Enter your first and last name" onChange={(e) => setNameInput(e.target.value)} />
                                                <br></br>
                                                {nameInput.length > 0 && !validateName(nameInput) && (
                                                    <label style={{ color: "rgb(255, 0, 0)" }}>
                                                        Please enter your <em>name</em>
                                                    </label>
                                                )}
                                                <br ></br>
                                                {/* Birthday Input */}
                                                <div style={{ marginTop: 15 }}>
                                                    <label>What is your birthdate?</label>
                                                </div>
                                                <div className="form-input-small-container">
                                                    <input type="text" value={birthdayMonthInput} className="form-input-small" placeholder="Month" maxLength={2} onChange={(e) => setBirthdayMonthInput(e.target.value)} />
                                                    <input type="text" value={birthdayDayInput} className="form-input-small" placeholder="Day" maxLength={2} onChange={(e) => {
                                                        setBirthdayDayInput(e.target.value)
                                                    }} />
                                                    <input type="text" value={birthdayYearInput} className="form-input-small" placeholder="Year" maxLength={4} onChange={(e) => setBirthdayYearInput(e.target.value)} />
                                                </div>
                                                {birthdayDayInput.length > 0 && birthdayMonthInput.length > 0 && birthdayYearInput.length > 0 && !validateBirthday(birthdayDayInput, birthdayMonthInput, birthdayYearInput) && (
                                                    <>
                                                        <label style={{ color: "rgb(255, 0, 0)" }}>
                                                            Please enter a valid <em>birthdate</em>
                                                        </label>
                                                    </>
                                                )}
                                            </form>
                                            <br></br>
                                        </div>
                                        <div className="signUpFormRight">
                                            <form>
                                                <label className="inputLabel">What is your address?</label>
                                                <br></br>
                                                {/* Address Input */}
                                                <div className="form-input-address-container">
                                                    <div>
                                                        <input type="text" value={streetInput} className="form-input" placeholder="Address" onChange={(e) => setStreetInput(e.target.value)} />
                                                    </div>

                                                    <div>
                                                        <input type="text" value={cityInput} className="form-input-medium-city" placeholder="City" onChange={(e) => setCityInput(e.target.value)} />
                                                        <input type="number" value={zipInput} className="form-input-zip" placeholder="Zipcode" onChange={(e) => setZipInput(e.target.value)} />
                                                    </div>
                                                    <div>
                                                        <input type="text" value={stateInput} className="form-input-medium" placeholder="State" onChange={(e) => setStateInput(e.target.value)} />
                                                    </div>
                                                </div>
                                                {/* If user entered invalid input, show validation error */}
                                                {streetInput.length > 0 && !validateAddress(streetInput) && (
                                                    <>
                                                        <label style={{ color: "rgb(255, 0, 0)" }}>
                                                            Please enter a valid <em>address</em>
                                                        </label>
                                                        <br></br>
                                                    </>
                                                )}
                                                {cityInput.length > 0 && !validateCity(cityInput) && (
                                                    <>
                                                        <label style={{ color: "rgb(255, 0, 0)" }}>
                                                            Please enter a valid <em>city</em>
                                                        </label>
                                                        <br></br>
                                                    </>
                                                )}
                                                {zipInput.length > 0 && !validateZip(zipInput) && (
                                                    <>
                                                        <label style={{ color: "rgb(255, 0, 0)" }}>
                                                            Please enter a valid <em>zipcode</em>
                                                        </label>
                                                        <br></br>
                                                    </>
                                                )}
                                                {stateInput.length > 0 && !validateState(stateInput) && (
                                                    <>
                                                        <label style={{ color: "rgb(255, 0, 0)" }}>
                                                            Please enter a valid <em>state</em>
                                                        </label>
                                                        <br></br>
                                                    </>
                                                )}
                                            </form>

                                            <br></br>

                                        </div>

                                    </div>

                                    <button className="mediumButton" onClick={() => {
                                        setTransitionStatePage2(false);
                                        setTimeout(() => {
                                            setPageState(0); setTransitionStatePage1(true)
                                        }, 200);
                                    }}>Back</button>
                                    <br></br>
                                    <button className="mediumButton" disabled={!isReadyForSignUp()} onClick={() => {
                                        signUp();
                                    }}>Sign Up</button>

                                    {errorMessage.length > 0 && (
                                        <>
                                            <br></br>
                                            <label style={{ color: "rgb(255, 0, 0)" }}>
                                                {errorMessage}
                                            </label>
                                            <br></br>
                                        </>
                                    )}
                                </div>
                            )}

                        </Transition>
                    )}
                    {/* If pageState is 1, show sign up part 2 */}
                    {pageState === 2 && (
                        <Transition in={transitionStatePage2} timeout={200} appear={true} >
                            {state2 => (
                                <div ref={nodeRef2} style={{
                                    ...defaultStyle,
                                    ...transitionStyles[state2]
                                }}>
                                    <div className="flexContainer">
                                        <div className="signUpForm">
                                            <form>
                                                <label className="inputLabel">We sent a verification code to <b>{emailInput}</b><br></br>Please enter your code below</label>
                                                <br></br>
                                                {/* Name Input */}
                                                <input type="number" className="form-input" aria-describedby="textHelp" placeholder="Verification code" onChange={(e) => setVerificationCodeInput(e.target.value)} />
                                                <br></br>

                                            </form>
                                            <br></br>
                                            <div className="signUpButtonContainer">
                                                <button className="nextButton" onClick={() => {
                                                    confirmSignUp();
                                                }}>Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </Transition>
                    )}
                </div>

            </div>

        </>
    )

}

export default SignUp;