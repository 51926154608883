import React, { useState, useEffect, useRef } from "react";
import "./AddLabel.css";
import { TwitterPicker } from 'react-color';
import Label from "./Label";
import { addLabelToDB, removeLabelFromDB } from "../Helpers/getData";

/**
 * Hook that alerts clicks outside of the passed ref
 */


function AddLabel(props) {
    const [color, setColor] = useState("#fff");
    const [newLabelName, setNewLabelName] = useState("");
    const [isSelected, setIsSelected] = useState(props.label);
    const [showAddLabel, setShowAddLabel] = useState(props.showAddLabel);
    const wrapperRef = useRef(null);
    const [labels, setLabels] = useState(props.labels);
    useOutsideAlerter(wrapperRef);

    useEffect(() => {
        setShowAddLabel(props.showAddLabel);
        setIsSelected(props.label);
    }, [props.showAddLabel]);

    /*
 *  This function handles the color picker
    * @param {string} color - The color of the label
    * @param {object} event - The event object
 */
    function handleChangeComplete(color, event) {
        setColor(color.hex);
    }

    /*
    *   This function checks if the label name already exists
    */
    function checkIfLabelExists() {
        for (let i = 0; i < props.labels.length; i++) {
            if (props.labels[i].title === newLabelName) {
                return true;
            }
        }
        return false;
    }

    /*
    *   This function checks if the label name is valid or exists
    */
    function checkAddLabelError() {
        if (newLabelName === "") {
            return "Enter a label name";
        }
        else if (checkIfLabelExists()) {
            return "Label already exists";
        }
        else {
            return "Add Label";
        }
    }

    /*
   *   This function updates the label input and updates the messages list
    * @param {object} label - The label object
   */
    function updateLabelSelection(label) {
        if (label === undefined || label.title === undefined || label.color === undefined) {
            removeLabelFromDB(props.messageID)
            setIsSelected(undefined);
            props.updateOnRemoveLabel(props.messageID)
        }
        else {
            confirmAddLabel(label);


        }

    }

    /*
    *   This function handles the confirmation of the add label popover
    * @param {object} label - The label object
    */
    function confirmAddLabel(label) {
        new Promise((resolve, reject) => {
            //Add new label to labels
            let newLabels = props.updateOnAddLabel(props.messageID, label.title, label.color)
            setLabels(newLabels);
            addLabelToDB(props.messageID, label)

            resolve()
        }).then(() => {
            //Scroll to bottom of labels

            let allLabelsDiv = document.getElementById("all-labels")
            allLabelsDiv.scrollTop = allLabelsDiv.scrollHeight + 50;
            setIsSelected(label);
        });
        setNewLabelName(""); //Reset new label name


    }

    /*
    *   This function handles the click outside of the popover
    * @param {object} ref - The ref object
    */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                let labelButton = document.getElementById("label-button-message-page")
                if (ref.current && !ref.current.contains(event.target) && !labelButton.contains(event.target)) {
                    props.handleLabelPopupClick(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    if (showAddLabel === false) {
        return null
    }
    else
        return (
            <>
                {/*Color Picker*/}
                {

                    <div className="popover" ref={wrapperRef}>
                        <div className="cover" />
                        <div className="label-title-container" style={{ marginBottom: "5px" }}>
                            <h3 className="label-text">Labels</h3>
                        </div>
                        <div className="hr-line-add-label-container">
                            <hr className="hr-line-full"></hr>
                        </div>
                        <div className="labels-container-add-label" id="all-labels">
                            {
                                labels.length !== 0 ?
                                    labels.map((label, index) => {
                                        return (
                                            <Label key={index} label={label} messageID={props.messageID} isSelected={isSelected} updateSelected={updateLabelSelection} />
                                        )
                                    }
                                    ) : <><br></br> <div className="label-title-container" style={{ textAlign: "center" }}><label className="attribute-label"><i>No labels</i></label> </div></>
                            }
                        </div>
                        <div className="hr-line-add-label-container">
                            <hr className="hr-line-full"></hr>
                        </div>
                        <div className="label-title-container">
                            <h3 className="label-text">Add Label</h3>
                        </div>

                        <TwitterPicker
                            styles={{ default: { boxShadow: "none !important" } }}
                            onChangeComplete={e => handleChangeComplete(e)}
                            color={color}
                            triangle={"hide"}
                        />
                        <input type="text" className="form-input-small-label-selector" placeholder="Name" onChange={e => setNewLabelName(e.target.value)}></input>
                        <br></br>
                        <button disabled={(newLabelName === "" || checkIfLabelExists())} className="nextButton-small-green" onClick={e => confirmAddLabel({ 'color': color, 'title': newLabelName })}>{checkAddLabelError()}</button>
                        <button className="nextButton-small" onClick={e => props.handleLabelPopupClick(false)}>Close</button>

                    </div>
                }


            </>
        )

}

export default AddLabel;