
import React, { useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import "./BarChart.css";
import { queryAllTopicCountsByDate, getAvgRatingByDate, getAllMessages } from "../Helpers/GetData";
import { DropdownList } from "react-widgets";
import "react-widgets/styles.css";
import { Loader } from '@aws-amplify/ui-react';
import secureLocalStorage from "react-secure-storage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getLoaderComponent } from "../../../Utils/Common";

function LineChart({ xAxis, yAxis, topics, command }) {
    const [Data, setData] = useState({
        labels: [],
        datasets: [
            {
                data: [],

            },
        ]

    });
    let currentDateMinus30Days = new Date();
    currentDateMinus30Days.setDate(currentDateMinus30Days.getDate() - 30);
    const [startDate, setStartDate] = useState(currentDateMinus30Days);
    const [endDate, setEndDate] = useState(new Date());
    const [title, setTitle] = useState("Trending Topics");
    const [isLoaded, setIsLoaded] = useState(false);
    const loggedInUser = JSON.parse(secureLocalStorage.getItem("user"));
    const [min, setMin] = useState(0);
    ChartJS.defaults.font.family = "AvenirLTProRoman"
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );


    const options = {

        plugins: {
            legend: {
                display: false
            }
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
        },
        scales: {
            y:
            {
                min: min,
                ticks: {
                    stepSize: 1,
                },
            },
            x:
            {

            },
        }
    }

    async function loadData() {
        if (command === "queryAllTopicCountsByDate" && !isLoaded) {
            getAllMessages(loggedInUser).then((messages) => {
                queryAllTopicCountsByDate(startDate, endDate, messages).then((data) => {
                    setData(data)
                    setTitle("Topic Counts by Date")
                    setIsLoaded(true)
                }).catch(err => console.log(err));
            }).catch(err => console.log(err));
        }
        else if (command === "getAvgRatingByDate" && !isLoaded) {
            getAllMessages(loggedInUser).then((messages) => {
                //call getReentAndOneMonthBefore to get the recent date and one month before date
                getAvgRatingByDate(startDate, endDate, messages).then((data) => {
                    setData(data)
                    setTitle("Average Sentiment by Date")
                    setIsLoaded(true)
                    setMin(1)
                    console.log("data")
                    console.log(data)
                }).catch(err => console.log(err));
            }).catch(err => console.log(err));
        }

    }

    loadData();


    if (isLoaded) {
        return (
            <>
                <div className="insights-card-top-container">
                    <div className="insights-card-title">
                        <h2>{title}</h2>
                    </div>
                    <div className="dropdown-container">

                        <DatePicker className={"date-picker"} selected={startDate} onChange={(date) => {
                            setStartDate(date)
                            setIsLoaded(false)
                            loadData()
                        }} />
                        <label className="attribute-label"><i>Start Date</i></label>
                    </div>
                    <div className="dropdown-container">

                        <DatePicker className={"date-picker"} selected={endDate} onChange={(date) => {
                            setEndDate(date)
                            setIsLoaded(false)
                            loadData()
                        }} />
                        <label className="attribute-label"><i>End Date</i></label>
                    </div>
                </div>
                {Data.labels.length < 1 ? (
                    <p>No data for selected dates</p>
                ) : (
                    <Line options={options} data={Data} />
                )}

            </>
        )
    }
    else {
        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", height: "100%" }}>
                {getLoaderComponent()}
            </div>
        )
    }


}

export default LineChart;